<template>
  <div>
    <div class="row">
      <div class="container text-end mt-2">
        <button class="btn btn-dark btn-sm mx-2 shadow-none" @click="close()" v-if="new_booking || activeBooking">
          <span class="material-icons">close</span>
          Close
        </button>
        <button class="btn btn-info btn-sm shadow-none" @click="save_for_later()" v-if="new_booking">
          <span class="material-icons">save</span>
          Save for later
        </button>
        <button class="btn btn-primary btn-sm shadow-none" @click="toggleNewBooking()" v-if="!new_booking">
          <span class="material-icons">add</span>
          Add booking
        </button>
      </div>
      <div class="col-md-9">
        <div class="row">
          <BookingCard 
          v-for="b in bookings"
          v-bind:key="b.id" :booking="b"
          @click="manage_booking(`${b.id}`)" 
          :active="activeBooking == b"
          />

          <p class="text-center" v-if="bookings.length == 0">
            There are no bookings running
          </p>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card shadow-none" style="max-height: 250px;overflow-y: scroll;">
          <div class="card-header p-0 border-bottom border-dark">
            <h6 class="px-2 mb-1">Pending</h6>
          </div>
          <div class="card-body p-0 pb-1">
            
            <div class="list-group border border-dark" v-if="bookings_pending.length > 0">
              <a @click="manage_booking(`${b.id}`)" v-for="b in bookings_pending" :key="b.id" class="py-2 d-flex border-bottom border-dark cursor-pointer" :class="activeBooking == b ? 'bg-secondary' : ''">
                <i class="material-icons text-dark px-2">female</i>
                {{ b.b_type }} 
                {{ b.reference.name }} 
                {{ b.sp.name }} 
              </a>
            </div>
            <div class="list-group border border-dark" v-if="bookings_saved.length > 0">
              <a @click="manage_booking(`${b.id}`)" v-for="b in bookings_saved" :key="b.id" class="py-2 d-flex border-bottom border-dark cursor-pointer" :class="activeBooking == b ? 'bg-secondary' : ''">
                <i class="material-icons text-dark px-2">female</i>
                {{ b.b_type }} 
                {{ b.reference.name }} 
                {{ b.sp.name }} 
              </a>
            </div>
            <div class="text-center" v-if="bookings_pending.length == 0 && bookings_saved.length == 0">
              <p class="text-sm">Nothing here</p>
            </div>

          </div>
        </div>
      </div>
    </div>
    <ManageBooking :booking="activeBooking" v-if="activeBooking" @refresh="getBookings" />
    <!-- <ManageBooking :booking="activeBooking" v-if="activeBooking" @refresh="(b) => refreshBooking(b)" /> -->
    <NewBooking v-if="new_booking" @refresh="getBookings" />
    
    
  </div>
</template>

<script>
import axios from 'axios'
import Card from '@/components/Card.vue'
import BookingCard from '@/components/bookings/BookingCard.vue'
import ManageBooking from '@/components/bookings/ManageBooking.vue'
import NewBooking from '@/components/bookings/NewBooking.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'BookingsView',
  // emits: ['refresh'],
  components: {
    Card,
    BookingCard,
    ManageBooking,
    NewBooking,
    // DataTable
  },
  data() { 
    return {
      bookings: [],
      bookings_pending: [],
      bookings_saved: [],
      activeBooking: null,
      new_booking: false,
      // activeSale: null,
      
    }
  },
  async mounted() { 
    console.log("mounted");
    this.getBookings()
  },
  watch: {
    bookings(nb, ob) {
      console.log('bookings changed', nb, ob);
     }
  },
  methods: {
    manage_booking(id) {
      if (id) {
        this.$store.commit('setIsLoading', true)
        console.log("show booking", id, this);
        this.activeBooking = this.$store.getters.getBookingById(id)
        this.new_booking = false
        // this.activeSale = this.activeBooking.sale
        console.log('activeBooking: ', this.activeBooking);
        this.$store.commit('setIsLoading', false)
      } else {
        console.log('invalid id');
        this.activeBooking = null
      }
    },
    toggleNewBooking() { 
      this.new_booking = true
      this.activeBooking = null
    },
    close() { 
      this.activeBooking = null
      this.new_booking = false
    },
    save_for_later() { 
      this.activeBooking = null
      this.new_booking = false
    },
    refreshBooking(booking) { 
      console.log('refresh booking', booking.id);
      console.log(booking);
      // this.bookings[booking.id] = booking
      var filtered = this.bookings.find(b => b.id == booking.id)
      console.log('found booking', filtered, typeof(filtered), this.bookings.indexOf(filtered));
      // this.bookings[this.bookings.indexOf(filtered)] = booking
      
      this.$store.commit('setBooking', booking)
    },
    async getBookings() {
      // console.log('getBookings');
      this.$store.commit('setIsLoading', true)
      if (!this.bookings.includes(this.activeBooking)) {
        this.activeBooking = null
      }
      this.bookings = []
      this.bookings_pending = []
      // console.log('dont have bookings', this.$store.state.bookings);
      // console.log('loading bookings');
      await this.$store.dispatch('loadBookings')
      this.bookings = this.$store.getters.getActiveBookings
      this.bookings_pending = this.$store.getters.getPendingBookings
      
      this.$store.commit('setIsLoading', false)
      this.close()
    }
  },
  computed: {
    ...mapGetters([
      'getActiveBookings',
      'getPendingBookings',
      'getBookingById',
    ])
  }
}

</script>
<template>
<div>
    <div class="page-header align-items-start min-vh-50 pt-7" :style="`background-image:url('${require('@/assets/img/logo_display.jpg')}');`">
    <!-- <div class="page-header align-items-start min-vh-50 pt-7 pb-9" style="background-image:url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-cover.jpg');"> -->
        <span class="mask bg-gradient-dark opacity-8"></span>
        <!-- <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-5 text-center mx-auto">
                    <h1 class="text-white mb-2">Welcome!</h1>
                    <p class="text-lead text-white"> Use these awesome forms to login or create new account in your project for free. </p>
                </div>
            </div>
        </div> -->
    </div>
    <div class="container">
        <div class="row mt-lg-n20 mt-md-n20 mt-n12 justify-content-center">
            <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
                <div class="card mt-5">
                    <div class="card-header pb-0 text-start">
                        <h3 class="font-weight-bolder">Welcome back</h3>
                        <p class="mb-0">Enter your email and password to sign in</p>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submitForm" class="text-start">
                            <div class="input-group input-group-outline my-3">
                                <input type="text" class="form-control" placeholder="username" v-model="username"/>
                            </div>
                            <div class="input-group input-group-outline mb-3">
                                <input type="password" class="form-control" placeholder="p455w0rd" v-model="password"/>
                            </div>
                            <div class="form-check form-switch d-flex align-items-center mb-3">
                                <input class="form-check-input" type="checkbox" id="rememberMe" checked="">
                                <label class="form-check-label mb-0 ms-3" for="rememberMe">Remember me</label>
                            </div>
                            <div class="text-center">
                                <button type="submit" class="btn bg-gradient-primary w-100 my-4 mb-2">Sign in</button>
                            </div>
                            <!-- <p class="mt-4 text-sm text-center">
                                Don't have an account?
                                <a href="../../../pages/authentication/signup/illustration.html" class="text-primary text-gradient font-weight-bold">Sign up</a>
                            </p> -->
                        </form>
                    </div>
                    <!-- <div class="card-footer text-center pt-0 px-lg-2 px-1">
                        <p class="mb-4 text-sm mx-auto"> Don't have an account? <a href="#/authentication/signup/cover" class="text-success font-weight-bold">Sign up</a></p>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Login2View',
    data() {
        return {
            // username: 'd1',
            // password: 'inferno1212',
            errors: [],
        }
    },
    computed: {

    },
    methods: {
        async submitForm(e) {

            this.$store.commit('setIsLoading', true)

            // axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
            axios.defaults.headers.common['Authorization'] = ''
            localStorage.removeItem('token')

            const formData = {
                username: this.username,
                password: this.password,
            }
            // axios.post(`http://localhost:8000/api/token`, formData)
            await axios.post(`/token/login`, formData)
            .then(response => {
                console.log("response:", response);
                const token = response.data.auth_token
                this.$store.commit('setToken', token)
                axios.defaults.headers.common['Authorization'] = 'Token ' + token
                localStorage.setItem('token', token)
                // console.log("is logged:", this.$store.state.isLogged);
                // console.log("is logged:", this.$store.state.isLogged);
                // this.$store.state.isLogged = true

                // this.$store.dispatch('fetchUser')
               //
                
            })
            .then(async () => {
                await this.$store.dispatch('fetchProfile').then(() => { 

                    console.log('done dispatching fetch Profile');
                    console.log('this.$store.state.profile', this.$store.state.profile);
    
                    if (this.$store.state.profile.user.groups[0].name == 'Admin') {
                        this.$router.push('/')
                    } else {
                        this.$router.push('/dashboard/register')
                    }
                    
                })
            })
            .catch(e => {
                // console.log(e);
                this.errors.push(e)
            })
            
            this.$store.commit('setIsLoading', false)

            
        }
    }
}

</script>
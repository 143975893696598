<template>
  <div class="row">
    <div class="col-md-6">

      <div class="card mb-2">
        <div class="card-header p-2">
          <h5>Store Settings</h5>
        </div>
        <div class="card-body">
          <pre>{{ $store.state.profile.current_store }}</pre>
        </div>
      </div>
      <div class="card my-2">
        <div class="card-header p-2">
          <h5>Store Managers</h5>
        </div>
        <div class="card-body">
          <pre>-</pre>
        </div>
      </div>
      <div class="card my-2">
        <div class="card-header p-2">
          <h5>Bar</h5>
        </div>
        <div class="card-body">
          <ul>
            <li>Products</li>
            <li>Category</li>
            <li>suppliers</li>
            <li>-email</li>
            <li>-notification</li>
            <li>-- when run out of stock</li>
            <li>-- when restock</li>
          </ul>
          <pre>-</pre>
        </div>
      </div>

    </div>
    <div class="col-md-6">
      
      <div class="card">
        <div class="card-header p-2">
          <h5>Booking Settings</h5>
        </div>
        <div class="card-body">
          <ul>
            <li>types</li>
            <li>wait time</li>
            <li>beeping</li>
          </ul>
          <pre>{{ $store.state.booking_types }}</pre>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
    name: "StoreSettingsView",
}
</script>

<style>

</style>
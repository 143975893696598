<template>
  <!-- <nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none" id="navbarBlur" data-scroll="false">
    <div class="container-fluid py-1 px-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
        <li class="breadcrumb-item text-sm">
          <a class="opacity-3 text-white" href="javascript:;">
            <span class="material-icons">home</span>
          </a>
        </li>
        <li class="breadcrumb-item text-sm"><a class="opacity-5 text-white" href="javascript:;">Pages</a></li>
        <li class="breadcrumb-item text-sm active text-white" aria-current="page">Sales</li>
      </ol>
      <h6 class="font-weight-bolder mb-0">{{ $route.name }}</h6>
    </nav>
    <div class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none ">
      <a href="javascript:;" class="nav-link p-0 text-body">
        <div class="sidenav-toggler-inner">
          <i class="sidenav-toggler-line"></i>
          <i class="sidenav-toggler-line"></i>
          <i class="sidenav-toggler-line"></i>
        </div>
      </a>
    </div>

    <div class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
    <div class="ms-md-auto pe-md-3 d-flex align-items-center">
      <div class="input-group input-group-outline">
        <label class="form-label">Search here</label>
        <input type="text" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)">
      </div>
    </div>
    <ul class="navbar-nav  justify-content-end">
      <li class="nav-item">
        <a href="../../pages/authentication/signin/illustration.html" class="nav-link p-0 position-relative text-body" target="_blank">
          <i class="material-icons me-sm-1">
          account_circle
          </i>
        </a>
      </li>
      <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
        <a href="javascript:;" class="nav-link p-0 text-body" id="iconNavbarSidenav">
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </a>
      </li>
      <li class="nav-item px-3">
        <a href="javascript:;" class="nav-link p-0 text-body">
          <i class="material-icons fixed-plugin-button-nav cursor-pointer">
          settings
          </i>
        </a>
      </li>
      <li class="nav-item dropdown pe-2">
        <a href="javascript:;" class="nav-link p-0 position-relative text-body" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
          <i class="material-icons cursor-pointer">
          notifications
          </i>
          <span class="position-absolute top-5 start-100 translate-middle badge rounded-pill bg-danger border border-white small py-1 px-2">
          <span class="small1">3</span>
          <span class="visually-hidden">unread notifications</span>
          </span>
        </a>
        <ul class="dropdown-menu dropdown-menu-end p-2 me-sm-n4" aria-labelledby="dropdownMenuButton">
          <li class="mb-2">
            <a class="dropdown-item border-radius-md" href="javascript:;">
            <div class="d-flex align-items-center py-1">
            <span class="material-icons">email</span>
            <div class="ms-2">
            <h6 class="text-sm font-weight-normal my-auto">
            Check new messages
            </h6>
            </div>
            </div>
            </a>
          </li>
          <li class="mb-2">
            <a class="dropdown-item border-radius-md" href="javascript:;">
            <div class="d-flex align-items-center py-1">
            <span class="material-icons">podcasts</span>
            <div class="ms-2">
            <h6 class="text-sm font-weight-normal my-auto">
            Manage podcast session
            </h6>
            </div>
            </div>
            </a>
          </li>
          <li>
            <a class="dropdown-item border-radius-md" href="javascript:;">
            <div class="d-flex align-items-center py-1">
            <span class="material-icons">shopping_cart</span>
            <div class="ms-2">
            <h6 class="text-sm font-weight-normal my-auto">
            Payment successfully completed
            </h6>
            </div>
            </div>
            </a>
          </li>
        </ul>
      </li>
    </ul>
    </div>
    </div>
  </nav> -->

  <nav class="navbar navbar-main bg-gradient-dark1 navbar-expand-lg position-sticky1 mt-1 top-1 px-0 mx-4 shadow-none border-radius-xl z-index-sticky" id="navbarBlur" data-scroll="true">
    <div class="container-fluid py-0 px-3">
      
      <ul class="navbar-nav justify-content-end btn btn-link d-xl-block">
        <li class="nav-item px-2" @click="$router.go(-1)" v-if="createBreadCrubms.length > 2">
          <a href="javascript:;" class="nav-link text-body p-0">
            <span class="material-icons cursor-pointer" >arrow_back</span>
            Go back
          </a>
        </li>
      </ul>
      
      <!-- <pre>{{ $route.fullPath }}</pre> -->

      <nav aria-label="breadcrumb">
        <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
          <li class="breadcrumb-item text-sm" v-for="crumb in createBreadCrubms" :key="crumb" :class="$route.name == crumb ? 'text-white active' : ''">
            {{ crumb.charAt(0).toUpperCase() + crumb.slice(1) }}
          </li>
        </ol>
        <h6 class="font-weight-bolder text-white mb-0">{{ $route.name.charAt(0).toUpperCase() + $route.name.slice(1) }}</h6>
      </nav>

      

      <div class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
        <div class="ms-md-auto pe-md-3 d-flex align-items-center">
          <div class="badge badge-dark">
            {{ $store.state.profile.user.groups[0].name }}
          </div>
        </div>
        
        <ul class="navbar-nav  justify-content-end" v-if="1==2">
          <li class="nav-item dropdown pe-2">
            <a href="javascript:;" class="nav-link text-body p-0 position-relative" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="material-icons cursor-pointer text-white">
            notifications
          </i>
              <span class="position-absolute top-5 start-100 translate-middle badge rounded-pill bg-danger border border-white small py-1 px-2">
                <span class="small1">3</span>
                <span class="visually-hidden">unread notifications</span>
              </span>
            </a>
            
            <ul class="dropdown-menu dropdown-menu-end p-2 me-sm-n4" aria-labelledby="dropdownMenuButton">
              
              <li class="mb-2">
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="d-flex align-items-center py-1">
                    <div class="my-auto">
                      <span class="material-icons">
                        email
                      </span>
                    </div>
                    <div class="ms-2">
                      <h6 class="text-sm font-weight-normal mb-0">
                        New message from Laur
                      </h6>
                    </div>
                  </div>
                </a>
              </li>
              <li class="mb-2">
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="d-flex align-items-center py-1">
                    <div class="my-auto">
                      <span class="material-icons">
                        headphones
                      </span>
                    </div>
                    <div class="ms-2">
                      <h6 class="text-sm font-weight-normal mb-0">
                        New album by Travis Scott
                      </h6>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="d-flex align-items-center py-1">
                    <div class="my-auto">
                      <span class="material-icons">
                        shopping_cart
                      </span>
                    </div>
                    <div class="ms-2">
                      <h6 class="text-sm font-weight-normal mb-0">
                        Payment successfully completed
                      </h6>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </li>

          <!-- <li class="nav-item">
            <div class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none ">
              <a href="javascript:;" class="nav-link text-body p-0">
                <div class="sidenav-toggler-inner text-white">
                  <i class="sidenav-toggler-line bg-white"></i>
                  <i class="sidenav-toggler-line bg-white"></i>
                  <i class="sidenav-toggler-line bg-white"></i>
                </div>
              </a>
            </div>
          </li> -->
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center" @click="openMenu()">
            <a href="javascript:;" class="nav-link text-body p-0" id="iconNavbarSidenav">
              <!-- <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div> -->
              <span class="material-icons fs-3 p-2" v-if="menuOpen">menu_open</span>
              <span class="material-icons fs-3 p-2" v-else>menu</span>
            </a>
          </li>


          <!-- <li class="nav-item px-3">
            <a href="javascript:;" class="nav-link text-body p-0">
              <i class="material-icons fixed-plugin-button-nav cursor-pointer text-white">
            settings
          </i>
            </a>
          </li> -->
          
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      menuOpen: false,
    }
  },
  created() {
    return this.$route.path
  },
  methods: {
    openMenu() { 
      this.menuOpen = !this.menuOpen
      this.$emit('toggle_sidebar')
    }
  },
  mounted() { 
    // this.createBreadCrubms();
  },
  computed: {
    createBreadCrubms() {
      let crumbs = this.$route.fullPath.split('/')
      crumbs.reverse().pop(0)
      crumbs.reverse()
      // console.log('crumbs', crumbs);
      return crumbs
    }
  }
};
</script>

<style>

</style>
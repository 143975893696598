<template>
    <section>
        <div class="page-header min-vh-100">
            <div class="container">
                <div class="row">
                   <div class="col-md-6 page-header">
                        <div class="rounded mx-auto d-block bg-danger h-100" :style="`background-image: url('${require('@/assets/img/logo_display.jpg')}'); background-size: cover; background-position-x: -80px`">
                        </div>
                   </div>
                   <div class="col-md-6">
                        <div class="card card-plain">
                            <div class="card-header text-center">
                                <h4 class="font-weight-bolder">Sign In</h4>
                                <p class="mb-0">Enter your email and password to sign in</p>
                            </div>
                            <div class="card-body mt-2">
                                <form role="form">
                                    <div class="input-group input-group-outline mb-3">
                                        <!-- <label class="form-label">Email</label> -->
                                        <input type="email" class="form-control" v-model="username">
                                    </div>
                                    <div class="input-group input-group-outline mb-3">
                                        <!-- <label class="form-label">Password</label> -->
                                        <input type="password" class="form-control" v-model="password">
                                    </div>
                                    <div class="form-check form-switch d-flex align-items-center mb-3">
                                        <input class="form-check-input" type="checkbox" id="rememberMe">
                                        <label class="form-check-label mb-0 ms-3" for="rememberMe">Remember me</label>
                                    </div>
                                    <div class="text-center">
                                    <button type="button" class="btn btn-lg bg-gradient-primary btn-lg w-100 mt-4 mb-0" @click="performLogin">Sign in</button>
                                    </div>
                                </form>
                            </div>
                            <div class="card-footer text-center pt-0 px-lg-2 px-1">
                                <p class="mb-4 text-sm mx-auto">
                                    Don't have an account?
                                    <a href="#" class="text-primary text-gradient font-weight-bold">Sign up</a>
                                </p>
                            </div>
                        </div>
                   </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: 'LoginView',
    data() { 
        return {
            'username': '',
            'password': '',
        }
    },
    methods: {
        performLogin(e) { 
            const formData = {
                username: this.username,
                password: this.password,
            }
            console.log(formData);
            //axios request
        }
    }
}

</script>
import { createStore } from 'vuex'
import { isProxy, toRaw } from 'vue';
import axios from 'axios'
export default createStore({
    state: {
        isLoading: false,
        isAuthenticated: false,
        token: '',
        user: {},
        profile: {},
        app_settings: {
            muteBookings: false,
            darkMode: true,
            showDebug: false,
        },
        bookings: [],
        booking_types: [],
        service_providers: [],
        reference: [],
        manager_shifts: [],
        products: [],
        inventory: [],

    },
    getters: {
        getUserProfile(state) {
            return state.profile
        },
        getActiveBookings(state) {
            if (state.bookings) {
                // return state.bookings.filter(b => b.status == 'rn' || b.status == 'pa' || b.status == 'p')
                return state.bookings.filter(b => !b.finished && b.status != 'p')
            }
        },
        getPendingBookings(state) {
            if (state.bookings) {
                return state.bookings.filter(b => b.status == 'p')
            }
        },
        getBookingById: (state) => (id) => {
            console.log('getBookingById');
            if (state.bookings) {
                var filtered = state.bookings.find(b => b.id == id)
                console.log('filtered', filtered);
                return filtered
                // return state.bookings.filter(b => b.id === id)
            }
            console.log('no bookings');
        },
        getDarkMode(state) {
            return state.app_settings.darkMode
        },
        getBookingTypes(state) {
            return state.booking_types
        },
        getServiceProvidersBusy(state) {
            console.log('get sp on duty');
            console.log('profile', state.profile);
            if (state.service_providers) {
                return state.service_providers.filter(sp => sp.busy) //&& sp.store == state.profile.current_store.id)
            }
        },
        getServiceProvidersOnDuty(state) {
            if (state.service_providers) {
                return state.service_providers.filter(sp => sp.getActiveShift != null)
            }
        },
        getServiceProviders(state) {
            if (state.service_providers) {
                return state.service_providers//.filter(sp => sp.store == state.profile.current_store.id)
            }
        },
        getServiceProviderById: (state) => (id) => {
            console.log('getServiceProvider', id);
            console.log('state.service_providers', state.service_providers);
            if (state.service_providers) {
                var filtered = state.service_providers.find(sp => sp.id == id)
                console.log('filtered sp', filtered);
                return filtered
                // return state.bookings.filter(b => b.id === id)
            } else {
                console.log('else nothing found');
            }
            console.log('nothing found');
        },
        getReferences(state) {
            return state.reference
        },
        getManagerShifts(state) {
            return state.manager_shifts
        },
        getManagerShifts_active: (state) => (register) => {
            if (register != null) {
                return state.manager_shifts.filter(shift => shift.finish_datetime == null && shift.get_daySale.register.name == register)
            } else {
                return state.manager_shifts.filter(shift => shift.finish_datetime == null)
            }
        },
        getManagerShiftById: (state) => (id) => {
            if (state.manager_shifts) {
                var filtered = state.manager_shifts.find(sp => sp.id == id)
                console.log('filtered sp', filtered);
                return filtered
                // return state.bookings.filter(b => b.id === id)
            } else {
                console.log('else nothing found');
            }
        },
        getProducts(state) {
            return state.products
        },
        getProductById: (state) => (id) => {
            return state.products.find(p => p.id == id)
        },
        getInventory(state) {
            return state.inventory
        },
        getInventoryById: (state) => (id) => {
            var item = state.inventory.find(p => p.id == id)
            if (typeof (item) == 'object') return item
            // return typeof (item) == 'object' ? item.name : '-'
        },
    },
    actions: {
        async fetchUser({ state, commit }) {
            //get user
            await axios.get(`/users/me`)
                .then(response => {
                    // console.log("response:", response);
                    console.log("set user:", response.data);
                    // state.user = response.data
                    // this.commit('setUser', response.data)
                    console.log("set.");
                })
                .catch(error => {
                    console.log("error:", error);
                })
        },
        async fetchProfile({ commit }) {
            // profile
            console.log('dispatch fetch profile');

            await axios.get(`/profile`)
                .then(response => {
                    // console.log("response:", response);
                    console.log("set profile:", response.data);
                    this.state.profile = response.data
                    commit('setProfile', response.data)
                    console.log("profile is admin:", response.data.user.groups[0].name == "Admin");
                    if (response.data.user.groups[0].name == "Admin") {
                        commit('setShowDebug', true)
                        commit('setMuteBookings', true)
                    } else {
                        commit('setShowDebug', false)
                        commit('setMuteBookings', false)
                    }
                    console.log("set.");
                })
                .catch(error => {
                    console.log("error:", error);
                })
        },

        async loadBookings({ commit }) {
            console.log("Loading bookings");
            await axios.get(`/bookings?store=1&finished=false`)
                // await axios.get(`/bookings?store=1`)
                .then(response => {
                    // JSON responses are automatically parsed.
                    // this.bookings = response.data
                    commit('setBookings', response.data.results)
                    // console.log(response.data.results)
                })
                .catch(e => {
                    console.log(e);
                    this.errors.push(e)
                })
        },
        async loadBookingsOptions({ state }) {
            console.log("Loading products");
            // await axios.options(`/bookings`)
            await axios.get(`/booking_types?ordering=code&store=1`)
                .then(response => {
                    // this.bookings = response.data
                    // this.commit('setBookings', response.data.results)
                    console.log('booking options', response.data)
                    // console.log('booking options', response.data.actions)
                    // console.log('booking options', response.data.actions.POST.b_type.choices)
                    state.booking_types = response.data.results
                })
                .catch(e => {
                    console.log(e);
                    // this.errors.push(e)
                })
        },

        async loadServiceProviders({ commit }) {
            await axios.get(`/service_providers`)
                .then(response => {
                    commit('setServiceProviders', response.data.results)
                    console.log("set.");
                })
                .catch(error => {
                    console.log("error:", error);
                })
        },
        async loadProducts({ commit }) {
            console.log("Loading products");
            await axios.get(`/products`)
                .then(response => {
                    // this.products = response.data
                    console.log('got products', response.data.results)
                    commit('setProducts', response.data.results)
                })
                .catch(e => {
                    this.errors.push(e)
                })
        },
        async loadInventory({ commit }) {
            console.log("Loading inventory");
            await axios.get(`/inventory`)
                .then(response => {
                    // this.products = response.data
                    console.log('got inventory', response.data.results)
                    commit('setInventory', response.data.results)
                })
                .catch(e => {
                    this.errors.push(e)
                })
        },
        async loadReferences({ commit }) {
            console.log("Loading references");
            await axios.get(`/reference`)
                .then(response => {
                    // this.products = response.data
                    console.log(response.data)
                    commit('setReference', response.data.results)
                })
                .catch(e => {
                    this.errors.push(e)
                })
        },
        async loadManagerShifts({ commit }) {
            console.log("Loading ManagerShifts");
            await axios.get(`manager_shifts?store=1&ordering=-start_datetime&ordering=finish_datetime`)
                .then(response => {
                    // this.products = response.data
                    console.log(response.data)
                    commit('setManagerShifts', response.data.results)
                })
                .catch(e => {
                    this.errors.push(e)
                })
        },
    },
    mutations: {
        initializeStore(state, profile) {
            console.log('init store');
            console.log('state profile', state.profile);
            if (profile) {
                state.profile = profile
            }
            if (localStorage.getItem('token')) {
                state.token = localStorage.getItem('token')
                state.isAuthenticated = true
                // this.getUser()
            } else {
                state.token = ''
                state.isAuthenticated = false
            }
        },
        setIsLoading(state, status) {
            state.isLoading = status
        },
        setDarkMode(state, mode) {
            console.log('setting ..DarkMode', mode);
            state.app_settings.darkMode = mode
        },
        setShowDebug(state, mode) {
            console.log('setting ..showDebug', mode);
            state.app_settings.showDebug = mode
        },
        setMuteBookings(state, mode) {
            console.log('setting ..setMuteBookings', mode);
            state.app_settings.muteBookings = mode
        },
        setToken(state, token) {
            state.token = token
            state.isAuthenticated = true
        },
        removeToken(state) {
            state.token = ''
            state.isAuthenticated = false
        },
        removeUser(state) {
            state.user = {}
            state.profile = {}
        },
        setUser(state, user) {
            state.user = user
        },
        setProfile(state, profile) {
            // console.log('setting profile to', profile);
            state.profile = profile
        },
        setBookings(state, bookings) {
            state.bookings = bookings
        },
        setBooking(state, booking) {
            console.log('set booking', booking);
            var b = this.getters.getBookingById(booking.id)
            console.log('b', b, typeof (b), b);
            var index = state.bookings.indexOf(b)
            // state.bookings[index] = booking
            Object.assign(state.bookings[index], booking);
        },
        setServiceProviders(state, sps) {
            state.service_providers = sps
        },
        setReference(state, ref) {
            state.reference = ref
        },
        setManagerShifts(state, ref) {
            state.manager_shifts = ref
        },
        setProducts(state, products) {
            console.log('set products', products);
            state.products = products
            console.log('set products', state.products);
        },
        setInventory(state, inventory) {
            state.inventory = inventory
        }
    },
})
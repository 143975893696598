<template>
    <aside class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-gradient-dark" data-color="primary">
    <!-- <aside class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-transparent1" data-color="primary"> -->
        <div class="sidebar-header">
            <router-link to="/" class="navbar-brand1 m-0">
                <!-- <img src="../assets/logo.png" class="navbar-brand-img h-100" alt="main_logo" /> -->
                <img src="../assets/img/logo.png" class="navbar-brand-img1 w-100 p-5 py-2 my-2" alt="main_logo" />
                <!-- <span class="ms-1 font-weight-bold text-white">One33 Dashboard</span> -->
            </router-link>
        </div>
        <hr class="horizontal light mt-0 mb-2">
        <div class="collapse navbar-collapse w-auto h-auto">
            <ul class="navbar-nav">
                <li class="nav-item mb-2 mt-0">
                    <a data-bs-toggle="collapse" href="#ProfileNav" class="nav-link collapsed text-white" aria-controls="ProfileNav" role="button" aria-expanded="false">
                        <img :src="`${$store.state.profile.image_url}`" class="avatar" :alt="$store.state.profile.user.username" v-if="$store.state.profile.image_url" />
                        <span class="nav-link-text ms-2 ps-1">{{ $store.state.profile.user.username }}</span>
                    </a>
                    <!-- <pre>{{$router}}</pre> -->
                    <div class="collapse" id="ProfileNav">
                        <ul class="nav">
                            <li class="nav-item">
                                <router-link to="/profile" class="nav-link text-white">
                                    <!-- <i class="material-icons-round">Person</i>
                                    <span class="nav-link-text ms-2 ps-1">Profile</span> -->
                                    <span class="sidenav-mini-icon material-icons-round">person</span>
                                    <span class="sidenav-normal ms-3 ps-1"> Profile </span>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link text-white" to="/profile/settings">
                                    <span class="sidenav-mini-icon material-icons-round">settings</span>
                                    <span class="sidenav-normal ms-3 ps-1">Settings</span>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link text-white" href="#!" @click="logout">
                                    <span class="sidenav-mini-icon material-icons-round font-xxs">logout</span>
                                    <span class="sidenav-normal ms-3 ps-1">Logout</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="nav-item mt-3" v-if="isAdmin">
                    <h6 class="ps-4 ms-2 text-uppercase text-xs text-white font-weight-bolder opacity-8">Admin pages</h6>
                </li>
                <li class="nav-item" v-if="isAdmin">
                    <router-link to="/" class="nav-link text-white">
                        <i class="material-icons-round">house</i>
                        <span class="nav-link-text ms-2 ps-1">Home</span>
                    </router-link>
                </li>
                
                <li class="nav-item" v-if="isAdmin">
                    <router-link to="/dashboard/products" class="nav-link text-white">
                        <i class="material-icons-round">inventory_2</i>
                        <span class="nav-link-text ms-2 ps-1">Products</span>
                    </router-link>
                </li>
                <li class="nav-item" v-if="isAdmin">
                    <router-link to="/dashboard/manager_shifts" class="nav-link text-white">
                        <i class="material-icons-round">pending_actions</i>
                        <span class="nav-link-text ms-2 ps-1">Manager Shifts</span>
                    </router-link>
                </li>
                <li class="nav-item" v-if="isAdmin">
                    <router-link to="/dashboard/store_settings" class="nav-link text-white">
                        <i class="material-icons-round">store</i>
                        <span class="nav-link-text ms-2 ps-1">Store</span>
                    </router-link>
                </li>
                
                <li class="nav-item mt-3">
                    <h6 class="ps-4 ms-2 text-uppercase text-xs text-white font-weight-bolder opacity-8">Manager</h6>
                </li>
                <li class="nav-item">
                    <router-link to="/dashboard/register" class="nav-link text-white">
                        <i class="material-icons-round">account_balance_wallet</i>
                        <span class="nav-link-text ms-2 ps-1">Register</span>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/dashboard/bookings" class="nav-link text-white">
                        <i class="material-icons-round">book_online</i>
                        <span class="nav-link-text ms-2 ps-1">Bookings</span>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/dashboard/service_providers" class="nav-link text-white">
                        <i class="material-icons-round">female</i>
                        <span class="nav-link-text ms-2 ps-1">Service Providers</span>
                    </router-link>
                </li>
                <li class="nav-item mt-3" v-if="isAdmin">
                    <h6 class="ps-4 ms-2 text-uppercase text-xs text-white font-weight-bolder opacity-8">Testing</h6>
                </li>
                <li class="nav-item" v-if="isAdmin">
                    <router-link to="/about" class="nav-link text-white">
                        <i class="material-icons-round">question_mark</i>
                        <span class="nav-link-text ms-2 ps-1">About</span>
                    </router-link>
                </li>
                <li class="nav-item" v-if="isAdmin">
                    <router-link to="/faq" class="nav-link text-white">
                        <i class="material-icons-round">question_mark</i>
                        <span class="nav-link-text ms-2 ps-1">FAQ</span>
                    </router-link>
                </li>
                
                
            </ul>
        </div>
        <div class="sidenav-footer position-absolute w-100 bottom-0" v-if="!isAdmin">
            <div class="mx-3">
                <a class="btn btn-danger mt-1 w-100" href="#!" type="button" onclick="panic()">
                    <span id="panic_button">
                        <span id="loading" class="spinner-border spinner-border-sm" role="status"></span>
                        <i class="material-icons px-1">warning</i>
                        PANIC!
                    </span>
                </a>
            </div>
        </div>
    </aside>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import { onBeforeMount } from 'vue'
export default {
    name: 'Sidebar',
    // props:[],
    data() { 
        return {
            // profile: this.$store.getters.getUserProfile
            pinned: false
        }
    },
    methods: {
        parent(name) { return this.$route.parent == name },
        async logout() { 
            await axios.post('/token/logout/')
                .then(response => { 
                    console.log('Logged out')
                })
                .catch(error => { 
                    console.log(JSON.stringify(error))
                })

            axios.defaults.headers.common['Authorization'] = ''
            localStorage.removeItem('token')
            this.$store.commit('removeToken')
            this.$store.commit('removeUser')
            this.$router.push('/')
        }
    },
    // created() {
    //     console.log('sidebar created');
    //     this.profile = this.$store.getters.getUserProfile
    //     console.log('!! got profile', this.profile);
    // },
    computed: {
        ...mapGetters([
            'getUserProfile',
        ]),
        showParent() { 
            return this.$route.parent
        },
        isAdmin() { return this.$store.state.profile.user.groups[0].name == 'Admin' },
    }
}

</script>

<style scoped>
.nav-link.active {background: #d63384 !important}
.nav-link.active.active-exact {background: #c01369 !important}
</style>
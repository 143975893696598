<template>
  
  <div class="container" v-if="isAdmin">
    <div class="row py-4">
      <div class="col-sm-4">
        <Card headerClass="p-0">
            <template v-slot:body>
              <div class="row">
                <div class="col-7 text-start">
                  <p class="text-sm mb-1 text-capitalize font-weight-bold">Sales</p>
                    <h5 class="font-weight-bolder mb-0">
                      ${{ total_total }}
                    </h5>
                    <span class="text-sm text-end text-success font-weight-bolder mt-auto mb-0">
                      +55% <span class="font-weight-normal text-white opacity-8">since last month</span>
                    </span>
                </div>
                <div class="col-5">
                  <div class="dropdown text-end">
                    <a href="#!" class="cursor-pointer text-white opacity-8" id="dropdownUsers1" data-bs-toggle="dropdown" aria-expanded="false">
                      <span class="text-xs text-white opacity-8">
                        <input type="date" id="datepicker" class="text-white cursor-pointer" name="trip-start" value="2018-07-22" min="2018-01-01" max="2018-12-31" />
                      </span>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-end px-2 py-3" aria-labelledby="dropdownUsers1">
                      <li><a class="dropdown-item border-radius-md" href="#">Last 7 days</a></li>
                      <li><a class="dropdown-item border-radius-md" href="#">Last week</a></li>
                      <li><a class="dropdown-item border-radius-md" href="#">Last 30 days</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
        </Card>
      </div>
      <div class="col-sm-4 mt-sm-0 mt-4">
        <Card color="1bg-info" headerClass="p-0">
            <template v-slot:body>
              <p class="text-sm mb-1 text-capitalize font-weight-bold">Bar Sales</p>
              <h5 class="font-weight-bolder mb-0">
                ${{total_bar}}
              </h5>
              <span class="text-sm text-end text-success font-weight-bolder mt-auto mb-0">
                +55% <span class="font-weight-normal text-white opacity-8">since last month</span>
              </span>
            </template>
        </Card>
      </div>
      <div class="col-sm-4 mt-sm-0 mt-4">
        <Card headerClass="p-0">
          <template v-slot:body>
            <div class="d-flex flex-row-reverse justify-content-between">
              <div class="text-center shadow icon icon-shape bg-gradient-success rounded-circle">
                <i class="opacity-10 material-icons" aria-hidden="true">attach_money</i>
              </div>
              <div class="">
                <div class="numbers">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold">Reception</p>
                  <h5 class="mb-0 font-weight-bolder undefined">
                    $ {{total_reception}}
                    <span class="text-sm font-weight-bolder text-success"></span>
                  </h5>
                  <p class="mt-2 mb-0">
                    <span class="text-sm font-weight-bolder text-success">+3%</span> since last week</p>
                  </div>
                </div>
              </div>
          </template>
        </Card>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-4 col-sm-6 mx-auto">
        <Card :bodyClass="`p-1`" :headerClass="`px-2 py-2`" color="shadow-none">
          <template v-slot:header>
            <div class="d-flex justify-content-between">
              <h6 class="mb-0">Channels</h6>
              <button type="button" class="btn btn-icon-only btn-rounded btn-outline-secondary mb-0 ms-2 btn-sm d-flex align-items-center justify-content-center" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" data-bs-original-title="See traffic channels">
              <i class="material-icons text-sm">priority_high</i>
              </button>
            </div>
          </template>
          <template v-slot:body>
            <Bar
              id="my-chart-id"
              :options="chartOptions_channels"
              :data="chartData_channels"
            />
          </template>
        </Card>
      </div>
      <div class="col-lg-3 col-sm-6 mt-sm-0 mt-4" v-if="reception_active != null">
        <Card bodyClass="p-0" :headerClass="`p-2`">
          <template v-slot:header>
            <div class="d-flex justify-content-between p-0">
              <h6 class="mb-0">Reception</h6>
              <div>
                <span class="badge badge-sm badge-dark d-flex">
                  <span class="material-icons text-sm text-dark" v-if="reception_active.shift_type == 'night'">nightlight_round</span>
                  <span class="material-icons text-sm text-warning" v-if="reception_active.shift_type == 'day'">light_mode</span>
                  {{ reception_active.shift_type }}
                </span>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <ul class="list-group">
              <li class="list-group-item d-flex justify-content-between">
                <span class="d-flex py-1">
                  <span class="material-icons text-success" v-if="reception_active.finish_datetime == null">play_arrow</span>
                  <span class="material-icons text-danger" v-if="reception_active.finish_datetime != null">stop</span>
                  {{ reception_active.status }}
                </span>
                <div class="d-flex px-2">
                  <div>
                    <img src="@/assets/img/no-avatar.png" class="avatar avatar-sm rounded-circle me-2">
                  </div>
                  <div class="my-auto">
                    <h6 class="mb-0 text-xs">{{ reception_active.user }}</h6>
                  </div>
                </div>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                  Walk ins
                  <span class="badge badge-info badge-pill">{{ reception_active.walk_in }}</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                  Bookings
                  <span class="badge badge-info badge-pill">{{ reception_active.num_bookings }}</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                  Revenue
                  <span class="badge badge-info badge-pill">$0</span>
              </li>
            </ul>
          </template>
        </Card>
      </div>
      <div class="col-lg-3 col-sm-6 mt-sm-0 mt-4" v-if="bar_active">
        <Card :bodyClass="`p-0`" :headerClass="`p-2`">
          <template v-slot:header>
            <div class="d-flex justify-content-between p-0">
              <h6 class="mb-0">Bar</h6>
              <div>
                <span class="badge badge-sm badge-dark d-flex">
                  <span class="material-icons text-sm text-dark" v-if="bar_active.shift_type == 'night'">nightlight_round</span>
                  <span class="material-icons text-sm text-warning" v-if="bar_active.shift_type == 'day'">light_mode</span>
                  {{ bar_active.shift_type }}
                </span>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <ul class="list-group">
              <!-- <li class="list-group-item">DaySale object (187)</li> -->
              <li class="list-group-item d-flex justify-content-between">
                <span class="d-flex py-1">
                  <span class="material-icons text-success" v-if="bar_active.finish_datetime == null">play_arrow</span>
                  <span class="material-icons text-danger" v-if="bar_active.finish_datetime != null">stop</span>
                  {{ bar_active.status }}
                </span>
                
                <div class="d-flex px-2">
                  <div>
                    <img src="@/assets/img/no-avatar.png" class="avatar avatar-sm rounded-circle me-2">
                  </div>
                  <div class="my-auto">
                    <h6 class="mb-0 text-xs">
                      {{ bar_active.user }}
                    </h6>
                  </div>
                </div>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                  Sales
                  <span class="badge badge-info badge-pill">
                    {{ bar_active.num_bar_sales }}
                  </span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                  Spillage
                  <span class="badge badge-info badge-pill">
                    {{ bar_active.spillage_num }}
                  </span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center">
                  Revenue
                  <span class="badge badge-info badge-pill">$0</span>
              </li>
            </ul>
          </template>
        </Card>
      </div>
    </div>

    <div class="row mt-4">

      
      <!-- <div class="col-md-6" v-if="$store.state.app_settings.showDebug">
        <pre>{{ reception_active }}</pre>
        <pre>{{ bar_active }}</pre>
      </div> -->

    </div>
    <div class="row mt-4">

      <div class="col-lg-8 col-sm-6 mt-sm-0 mt-4">

        <Card bodyClass="p-0">
          <template v-slot:header>
            <h6 class="mb-0">Sales today</h6>
          </template>
          <template v-slot:body>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>id</th>
                    <th>sale type</th>
                    <th>date</th>
                    <th>payment type</th>
                    <th>amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="sale in sales" :key="sale.id">
                    <td>{{sale.id}}</td>
                    <td>{{ $filters.formatDate(sale.date_added) }}</td>
                    <td>
                      {{ sale.type }}
                    </td>
                    <td>
                      <span v-for="t in sale.transactions" :key="t.id">{{ t.payment_type }}</span>
                    </td>
                    <td>${{sale.grand_total}}</td>
                    <!-- <pre>{{ sale }}</pre> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
        </Card>

      </div>

      <div class="col-lg-4 col-sm-6">
        
        <Card :bodyClass="`p-3`" class="my-3">
          <template v-slot:header>
            <h6 class="mb-0">Sales by payment type</h6>
          </template>
          <template v-slot:body>
            ...
          </template>
        </Card>
        <Card :bodyClass="`p-3`">
          <template v-slot:header>
            <h6 class="mb-0">Sales by register</h6>
          </template>
          <template v-slot:body>
            ...
          </template>
        </Card>
        <Card :bodyClass="`p-3`" class="my-3">
          <template v-slot:header>
            <h6 class="mb-0">Top girls</h6>
          </template>
          <template v-slot:body>
            ...
          </template>
        </Card>
        <Card :bodyClass="`p-3`" class="my-3">
          <template v-slot:header>
            <h6 class="mb-0">Top products</h6>
          </template>
          <template v-slot:body>
            ...
          </template>
        </Card>

      </div>

    </div>
    <div class="row my-4">
      <div class="col-12">
        <Card headerClass="p-3" bodyClass="p-0" class="mb-4">
          <template v-slot:header>
            <h6>Top Selling Products</h6>
          </template>
          <template v-slot:body>
            <div class="table-responsive p-0">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-white opacity-8">Product</th>
                    <th class="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2 text-white opacity-8">Value</th>
                    <th class="text-center text-uppercase text-xxs font-weight-bolder opacity-7 text-white opacity-8">Ads Spent</th>
                    <th class="text-center text-uppercase text-xxs font-weight-bolder opacity-7 text-white opacity-8">Refunds</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="product in products" :key="product.id">
                    <td>
                      <div class="d-flex px-3 py-1">
                        <div>
                          <img :src="product.img" class="avatar me-3" alt="image" />
                        </div>
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">{{ product.name }}</h6>
                          <p class="text-sm font-weight-normal mb-0 text-white opacity-8"><span class="text-success">{{ product.orders }}</span> orders</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p class="text-sm font-weight-normal mb-0">${{ product.value }}</p>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <p class="text-sm font-weight-normal mb-0">${{ product.spend_ads }}</p>
                    </td>
                    <td class="align-middle text-end">
                      <div class="d-flex px-3 py-1 justify-content-center align-items-center">
                        <p class="text-sm font-weight-normal mb-0">{{ product.refunds }}</p>
                        <i class="material-icons-round ms-1 text-success" v-if="product.refunds < 20">expand_less</i>
                        <i class="material-icons-round ms-1 text-danger" v-else>expand_more</i>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
          </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
  <div v-if="!isAdmin">
    <RegisterView />
  </div>
  
</template>

<script>
// @ is an alias to /src
import Card from '@/components/Card.vue'
import RegisterView from '@/views/cash_register/RegisterView.vue'
import { Bar, Line, Pie } from 'vue-chartjs'
import axios from 'axios'

import { mapState } from 'vuex';

import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'HomeView',
  components: {
    Card,
    Bar,
    Line,
    RegisterView,
    Pie
},
  data() {
    return {
      chartData_channels: {
        labels: ['T', 'F', 'S', 'S', 'M', 'T', 'W'],
        datasets: [{
          label: "House income",
          tension: 0.4,
          borderWidth: 0,
          borderRadius: 2,
          borderSkipped: false,
          backgroundColor: "rgba(155, 255, 155, .8)",
          data: JSON.parse("[1, 4, 3, 10, 9, 11, 1]"),
          maxBarThickness: 6
        }, {
          label: "Revenue",
          tension: 0.4,
          borderWidth: 0,
          borderRadius: 2,
          borderSkipped: false,
          backgroundColor: "rgba(155, 155, 255, .8)",
          data: JSON.parse("[2, 4, 5, 0, 1, 4, 9]"),
          maxBarThickness: 6
        },],
      },
      chartOptions_channels: {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: false,
          }
        },
        interaction: {
          intersect: true,
          mode: 'index',
        },
        
      },

      posts: [],
      errors: [],
      active_shifts: [],
      reception_active: null,
      bar_active: null,
      sales: [],
      total_total: 0,
      total_bar: 0,
      total_reception: 0,
    }
  },
  computed: {
    ...mapState(['products', 'sales']),
    //http://localhost:8000/api/products
    isAdmin() { return this.$store.state.profile.user.groups[0].name == 'Admin' },
  },
  methods: {
    calculate() { 
      if (this.sales) { 
        for (let i = 0; i < this.sales.length; i++) {
          console.log("grand_total:", this.total_total);
          const sale = this.sales[i];
          // this.total_total += sale.grand_total
          if (sale.type == 'bar') { 
            this.total_bar += sale.grand_total
          }
          if (sale.type == 'book') { 
            this.total_reception += sale.house_share
          }
          
        }
      }
    },
    dateToYMD(date) {
      var d = date.getDate();
      var m = date.getMonth() + 1; //Month from 0 to 11
      var y = date.getFullYear();
      return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
    },
  },
  async mounted() {
    var date_today = new Date()
    var date_yesterday = new Date()
    var date_tomorrow = new Date()
    date_yesterday.setDate(date_yesterday.getDate() - 1);
    date_tomorrow.setDate(date_tomorrow.getDate() + 1);

    this.reception_active = this.$store.getters.getManagerShifts_active('Reception')[0]
    this.bar_active = this.$store.getters.getManagerShifts_active('Bar')[0]
    // console.log('active_shifts', this.active_shifts);
    
    // await axios.get(`/sales?date_added__lte=${ this.dateToYMD(date_tomorrow) }&date_added__gte=${this.dateToYMD(date_today)}`)
    // await axios.get(`/sales?date_added__lte=${ this.dateToYMD(date_tomorrow) }&date_added__gte=${this.dateToYMD(date_yesterday)}`)
    await axios.get(`/sales?date_added__lte=${ this.dateToYMD(date_tomorrow) }&date_added__gte=${this.dateToYMD(date_yesterday)}`)
    // await axios.get(`/sales?date_added__lte=${ this.dateToYMD(date_today) }&date_added__gte=${this.dateToYMD(date_yesterday)}`)
      .then(response => {
        // JSON responses are automatically parsed.
        this.posts = response.data
        console.log(response.data)
        this.sales = response.data.results
      })
      .catch(e => {
        this.errors.push(e)
      })

    this.calculate()
  },

}
</script>
<style>
#datepicker{border:0px;background: transparent;}
#datepicker > span:hover{cursor: pointer;}
</style>
<template>
    <div class="row mb-4">
        <div class="col-md-6">
            <Card headerClass="p-2" bodyClass="p-0">
                <template v-slot:header>
                    <h6 class="mb-0">On duty now</h6>
                    <p class="text-sm mb-0">
                        <span class="font-weight-bold mx-1">{{ service_providers_onDuty.length }} on duty</span> in total
                        <br />
                        <span class="font-weight-bold mx-1">
                            <span class="badge badge-dot"><i class="bg-success"></i></span>
                            {{ service_providers_onDuty.length - $store.getters.getServiceProvidersBusy.length }} available
                        </span>
                        
                        <span class="font-weight-bold mx-1">
                            <span class="badge badge-dot"><i class="bg-danger"></i></span>
                            {{ $store.getters.getServiceProvidersBusy.length }} busy
                        </span>
                        
                    </p>
                </template>
                <template v-slot:body>
                    <div class="table-responsive">
                        <table class="table table-striped table-sm align-items-center mb-0">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Rate</th>
                                    <th>Paid</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="service_providers_onDuty.length == 0">
                                    <td colspan="4">
                                        <p class="text-sm text-center">
                                            There are no service providers on duty
                                        </p>
                                    </td>
                                </tr>
                                <tr v-for="sp in service_providers_onDuty" :key="sp.id">
                                    <td>
                                        <router-link :to="`/dashboard/service_provider/${sp.id}`">
                                            <div class="d-flex px-2">
                                                <div>
                                                    <img v-if="sp.image_url" :src="sp.image_url" class="avatar avatar-sm rounded-circle me-2" :class="sp.busy ? 'border border-danger' : ''">
                                                    <img v-else src="@/assets/img/no-avatar.png" class="avatar avatar-sm rounded-circle me-2" :class="sp.busy ? 'border border-danger' : ''">
                                                </div>
                                                <div class="my-auto">
                                                    <h6 class="mb-0 text-xs">{{ sp.name }}</h6>
                                                </div>
                                            </div>
                                        </router-link>
                                    </td>
                                    <td class="text-center">
                                        <span class=" p-1 badge badge-sm" :class="sp.getActiveShift.rate == 'p' ? 'bg-warning' : ''">
                                            {{ $filters.formatRate(sp.getActiveShift.rate) }}
                                            <!-- {{ sp.getActiveShift.rate}} -->
                                        </span>
                                        <p class="text-sm font-weight-normal mb-0">
                                            
                                        </p>
                                    </td>
                                    <td>
                                        <!-- <p class="text-sm font-weight-normal mb-0">
                                            {{ formatDate(sp.getActiveShift.start_datetime) }}
                                        </p> -->
                                        <p class="text-sm mb-0" v-if="sp.getActiveShift.get_payout == null">
                                            <span class="p-0 border border-danger badge rounded-circle p-1 material-icons text-sm text-danger">priority_high</span>
                                            Not Paid
                                        </p>
                                        <p class="text-sm mb-0" v-else>
                                            <span class="p-0 border border-success badge rounded-circle p-1 material-icons text-sm text-success">check</span>
                                            Paid
                                        </p>
                                    </td>
                                    <td class="text-center">
                                        <button class="btn btn-sm btn-danger shadow-none m-0" @click="clock_out(sp.id, service_providers_rates[index])">
                                            <span class="material-icons">timer_off</span>
                                            Clock out
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
            </Card>
        </div>
        <div class="col-md-6 mb-4">
            <Card headerClass="p-2 d-flex justify-content-between" bodyClass="p-0">
                <template v-slot:header v-if="isAdmin">
                    <div class="d-flex">
                        <span class="material-icons pe-2">settings</span>
                        Admin Menu
                    </div>
                    <button class="btn btn-sm btn-outline-dark text-white shadow-none" @click="alert('under construction')">
                        <span class="material-icons">add</span>
                        Add new girl
                    </button>
                </template>
                <template v-slot:body>
                    <div class="bg-dark">
                        <div class="d-flex">
                            <span class="material-icons align-middle p-2">search</span>
                            <input type="text" v-model="search_sp" placeholder="Search" class="form-control px-2 rounded-0 border-0 text-white bg-transparent">
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-sm table-striped align-items-center mb-0">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Rate</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="service_providers.length == 0">
                                    <td colspan="4">
                                        <p class="text-sm text-center">
                                            There are no service providers available
                                        </p>
                                    </td>
                                </tr>
                                <tr v-for="(sp, index) in resultQuery" :key="sp.id">
                                    <td>
                                        <router-link :to="`/dashboard/service_provider/${sp.id}`">
                                            <div class="d-flex px-2">
                                                <div>
                                                    <img v-if="sp.image_url" :src="sp.image_url" class="avatar avatar-sm rounded-circle me-2" :class="sp.busy ? 'border border-danger' : ''">
                                                    <img v-else src="@/assets/img/no-avatar.png" class="avatar avatar-sm rounded-circle me-2" :class="sp.busy ? 'border border-danger' : ''">
                                                </div>
                                                <div class="my-auto">
                                                    <h6 class="mb-0 text-xs">{{ sp.name }}</h6>
                                                </div>
                                            </div>
                                        </router-link>
                                    </td>
                                    <td class="text-center">
                                        <ul class="list-group list-group-horizontal-sm" id="rate-selector-6">
                                            <li class="list-group-item text-sm cursor-pointer" :class="service_providers_rates[index] == 's' ? 'active' : ''" @click="setRate(index, 's')">
                                                Standard
                                            </li>
                                            <li class="list-group-item text-sm cursor-pointer" :class="service_providers_rates[index] == 'p' ? 'active' : ''" @click="setRate(index, 'p')">
                                                Premium
                                            </li>
                                        </ul>
                                    </td>
                                    <td class="text-center">
                                        <button class="btn btn-sm btn-success shadow-none" @click="clock_in(sp.id, service_providers_rates[index])">
                                            <span class="material-icons">timer</span>
                                            Clock in
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
            </Card>
        </div>
    </div>
    <div class="row my-4" v-if="this.$store.state.app_settings.showDebug">
        <div class="col-md-6">
            <div class="card card-body">
                <pre>{{ service_providers_onDuty }}</pre>
            </div>
        </div>
        <div class="col-md-6">
            <div class="card card-body">
                <pre>{{ service_providers }}</pre>
            </div>
        </div>
    </div>
</template>

<script>
import Card from '@/components/Card.vue'
import axios from 'axios'

export default {
    name: 'ServiceProvidersView',
    components: {
        Card,
    },
    data() { 
        return {
            service_providers_onDuty: [],
            service_providers: [],
            service_providers_rates: [],
            search_sp: null,
        }
    },
    methods: {
        setRate(id, rate) {
            this.service_providers_rates[id] = rate//!this.service_providers_rates[id]
        },
        async clock_out(sp, rate) { 
            this.$store.commit('setIsLoading', true)
            console.log('clock out', sp, rate);

            let data = {
                finish_datetime: new Date(),
            }
            await axios.post(`/service_providers/${sp}/shift`, data)
                .then(response => {
                    // JSON responses are automatically parsed.
                    this.posts = response.data
                    console.log(response.data)
                    // this.sales = response.data.results
                })
                .catch(e => {
                    this.errors.push(e)
                })
            this.$store.commit('setIsLoading', false)
            this.refresh_sp()
        },
        async clock_in(sp, rate) {
            this.$store.commit('setIsLoading', true) 
            console.log('clock in', sp, rate);
            // service_providers / <int:pk>/shift

            let data = {
                rate: rate,
                start_datetime: new Date(),
                // finish_datetime: "2022-08-30T00:01:16.689432Z",
            }
            await axios.post(`/service_providers/${sp}/shift`, data)
                .then(response => {
                    // JSON responses are automatically parsed.
                    this.posts = response.data
                    console.log(response.data)
                    // this.sales = response.data.results
                    //.find(b => b.id == id)
                    // let clocked_sp = this.service_providers.find(s => s.id == sp)
                    // this.service_providers.pop(clocked_sp)
                    // this.service_providers_onDuty.push(clocked_sp)
                })
                .catch(e => {
                    this.errors.push(e)
                })
            
            this.$store.commit('setIsLoading', false)
            this.refresh_sp()

        },
        async refresh_sp() { 
            this.$store.commit('setIsLoading', true)
            await this.$store.dispatch('loadServiceProviders')
            this.service_providers_onDuty = this.$store.getters.getServiceProvidersOnDuty
            this.service_providers = this.$store.getters.getServiceProviders
            this.service_providers = this.service_providers.filter(sp => { 
                console.log("!this.service_providers_onDuty.includes(sp)", !this.service_providers_onDuty.includes(sp));
                return !this.service_providers_onDuty.includes(sp)
            })
            // this.service_providers = array_diff = (a, b) => a.filter(v => !b.includes(v))
            // this.service_providers = this.$store.getters.getServiceProviders.filter((sp) => {
            //     // return those elements not in the namesToDeleteSet
            //     return !this.service_providers_onDuty.has(sp);
            // });
            // this.search_sp = this.service_providers
            for (let index = 0; index < this.service_providers.length; index++) {
                this.service_providers_rates.push('s') //* this.$store.getters.getServiceProviders.length
                // console.log('sp:', this.service_providers[index]);
                // console.log('sp in onDuty:', this.service_providers_onDuty.includes(this.service_providers[index]));
                // if (this.service_providers_onDuty.includes(this.service_providers[index])) { 
                //     this.service_providers.
                // }
            }
            
            this.$store.commit('setIsLoading', false)
        }
        // filteredList() {
        //     return this.service_providers_onDuty.filter((sp) =>
        //         sp.toLowerCase().includes(search_sp.value.toLowerCase())
        //     );
        // },
    },
    async mounted() {
        console.log("mounted");
        // this.$store.commit('setIsLoading', true)
        // await this.$store.dispatch('loadServiceProviders')
        // this.service_providers_onDuty = this.$store.getters.getServiceProvidersOnDuty
        // this.service_providers = this.$store.getters.getServiceProviders
        // // this.search_sp = this.service_providers
        // for (let index = 0; index < this.$store.getters.getServiceProviders.length; index++) {
        //     this.service_providers_rates.push('s') //* this.$store.getters.getServiceProviders.length
        // }
        // console.log('rates',this.service_providers_rates);
        // // console.log('luxon',this.$luxon("2020-10-05T14:48:00.000Z"));
        // this.$store.commit('setIsLoading', false)
        this.refresh_sp();
    },
    computed: {
        isAdmin() { return this.$store.state.profile.user.groups[0].name == 'Admin' },
        resultQuery() {
            if (this.search_sp) {
                return this.service_providers.filter(item => {
                    return this.search_sp
                        .toLowerCase()
                        .split(" ")
                        .every(v => item.name.toLowerCase().includes(v));
                });
            } else {
                return this.service_providers;
            }
        },
    }
}
</script>

<style>

</style>
<template>
  <Sidebar v-if="$store.state.profile.user" />
    <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
      <Navbar v-if="$store.state.profile.user" @toggle_sidebar="toggleSidebar" />
      
      <div class="container-fluid" v-bind:class="!hideSidebar ? `p-0 m-0` : ``">
        <!-- <BookingsView /> -->
        <router-view @darkModeChange="toggelDarkMode()" />
      </div>
      
      
    </main>
    <div class="container-fluid text-center bg-success">
      <div class="spinner-border spinner-border-md bg-dark shadow" style="display:flex;position: fixed;top: 40%;left: 50%;z-index: 1050;" v-if="$store.state.isLoading" role="status">
        <span class="sr-only"></span>
      </div>
    </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'
import Navbar from '@/components/Navbar.vue'
import BookingsView from '@/views/BookingsView.vue'
// import Footer from '@/components/Footer.vue'
import axios from 'axios'
// import $ from "jquery"
import '@/assets/js/bootstrap.js'

export default {
  name: 'App',
  components: {
    Sidebar,
    Navbar,
    BookingsView,
    // Footer,
  },
  data() { 
    return {
      sidebarPinned: false
      // darkMode: this.$store.state.app_settings.darkMode,
    }
  },
  // watch: {
  //   darkMode(val) {
  //     document.querySelector('#app').classList = [val]
  //   }
  // },
  computed: {
    hideSidebar() {
      return this.$route.name !== 'login' && this.$route.name !== 'login2' && this.$route.name !== 'home'
    },
  },
  // async beforeCreate() {
  //   this.$store.commit('setIsLoading', true)
  //   console.log('lengh:', Object.keys(this.$store.state.profile).length == 0);
    
  // },

  async mounted() {
    console.log('Mounted');
    console.log('App:', this.$el.parentElement);
    this.$store.commit('setIsLoading', true)
    
    await this.$store.commit('initializeStore')
    
    if (this.$store.state.token) {
      axios.defaults.headers.common['Authorization'] = "Token " + this.$store.state.token
    } else {
      axios.defaults.headers.common['Authorization'] = ""
    }
    

    if (this.$store.state.isAuthenticated && Object.keys(this.$store.state.profile).length == 0) {
      console.log('fetch userProfile --');
      // console.log("don't have user:", this.$store.getters.getUserProfile);
      // await this.$store.dispatch('fetchUser')
      await this.$store.dispatch('fetchProfile')
      var p = this.$store.getters.getUserProfile
      console.log("don't have user:", this.$store.state.profile);
      console.log('fetched ', p);
    }

    // this.toggelDarkMode()
    this.$store.commit('setIsLoading', false)

    

  },
  methods: {
    makeToast(append = false) {
      this.toastCount++
      this.$bvToast.toast(`This is toast number ${this.toastCount}`, {
        title: 'BootstrapVue Toast',
        autoHideDelay: 5000,
        appendToast: append
      })
    },
    toggelDarkMode() {
      console.log('toggle dark mode'); 
      if (this.$store.state.app_settings.darkMode) {
        console.log('dark-mode');
        this.$el.parentElement.classList.add("dark-version");
      } else {
        console.log('remove dark-mode');
        this.$el.parentElement.classList.remove("dark-version");
      }
    },
    toggleSidebar() { 
      // : class="pinned? 'g-sidenav-pinned' : ''"
      if (!this.sidebarPinned) {
        this.$el.parentElement.classList.add("g-sidenav-pinned");
      } else { 
        this.$el.parentElement.classList.remove("g-sidenav-pinned");
      }
      this.sidebarPinned = !this.sidebarPinned

    }
  }
}
</script>

<style scoped>
  @import 'assets/css/materialDashboard.css';
  /* @import 'assets/css/bootstrap.min.css'; */
  /* @import '@/assets/js/popper.js'; */
  
</style>

<template>
<div class="accordion">
  <div class="accordion-item mb-3">
    <a class="btn-link collapsed" role="button" data-bs-toggle="collapse" :data-bs-target="`#${id}`" aria-expanded="false" :aria-controls="`${id}`">
      <span class="collapse-close" aria-hidden="true"><slot name="open"></slot></span>
      <span class="collapse-open"><slot name="close"></slot></span>
      <!-- <i class="collapse-close material-icons me-3" aria-hidden="true">add</i>
      <i class="collapse-open material-icons me-3" aria-hidden="true">remove</i> -->
    </a>
    
    
    
    <div :id="id" class="accordion-collapse collapse" :data-bs-parent="`#${id}`">
      <div class="accordion-body text-sm opacity-8">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</div>

</template>

<script>
export default {
  name: 'Accordion',
    props: ['id']
}
</script>

<style>

</style>
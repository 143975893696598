<template>
  
  <div class="row">
    <div class="mt-4 col-12 col-md-6 mt-md-0 my-3 mx-auto">
      <Card style="max-width: 680px;" bodyClass="p-0" :headerClass="`p-2 d-flex justify-content-between`">
        <template v-slot:header v-if="debug_getActiveShift">
          <h6 class="mb-0">Cash register</h6>
          <div class="badge badge-info text-xl">{{ debug_getActiveShift.get_daySale.register.name }}</div>
        </template>
        <template v-slot:body >

          
          <div class="container p-0" v-if="debug_getActiveShift == null">
            <div class="px-5" v-if="page == 0">
              <div class="text-center">
                <h6 class="mb-1 fs-5">Welcome back</h6>
                <h6 class="mb-1 text-sm">Please select shift type</h6>
              </div>

              <div class="btn-group w-100 px-2 py-5 mt-2" role="group" aria-label="Shift type">
                <a href="#!" class="btn bg-gradient-secondary shadow-none py-5" @click="select_type('day')">
                  <span class="material-icons text-warning">light_mode</span>
                  Day shift
                </a>
                <a href="#!" class="btn bg-gradient-dark shadow-none py-5" @click="select_type('night')">
                  <span class="material-icons text-dark">nightlight_round</span>
                  Night Shift
                </a>
              </div>
            </div>

            <div class="px-5" v-if="page == 1">
              <div class="text-center">
                <h6 class="mb-1 fs-5">Open Register</h6>
                <h6 class="mb-1 text-sm">Please select cash register</h6>
              </div>
              <div class="btn-group w-100 px-2 py-5 mt-2" role="group" aria-label="Basic example">
                <a href="#!" class="btn bg-gradient-secondary shadow-none py-5" @click="select_register('Bar')">
                  Bar
                </a>
                <a href="#!" class="btn bg-gradient-info shadow-none py-5" @click="select_register('Reception')">
                  Reception
                </a>
              </div>
            </div>

            <div v-if="page==2">
              <div class="text-start px-4">
                <h6 class="mb-1 fs-5">Open Register Sum</h6>
                <h6 class="mb-1 text-sm">Please select cash amount to open register.</h6>
              </div>
              <div class="table table-responsive">
                <table class="table table-striped mb-0" id="open">
                  <thead>
                    <tr>
                      <th class="text-uppercase text-secondary1 text-xxs font-weight-bolder">
                        Note</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder ps-2">
                        -
                      </th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder ps-2">
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-center fs-5">$100</td>
                      <td>
                          <Counter @change="(value) => setNote(100, value)" />
                      </td>
                      <td class="text-center">
                          <p class="fs-5 mb-0">
                            ${{ (100 * notes['100']).toFixed(2) }}
                          </p>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center fs-5">$50</td>
                      <td>
                          <Counter @change="(value) => setNote(50, value)" />
                      </td>
                      <td class="text-center">
                          <p class="fs-5 mb-0">
                            ${{ (50 * notes['50']).toFixed(2) }}
                          </p>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center fs-5">$20</td>
                      <td>
                          <Counter @change="(value) => setNote(20, value)" />
                      </td>
                      <td class="text-center">
                          <p class="fs-5 mb-0">
                            ${{ (20 * notes['20']).toFixed(2) }}
                          </p>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center fs-5">$10</td>
                      <td>
                          <Counter @change="(value) => setNote(10, value)" />
                      </td>
                      <td class="text-center">
                          <p class="fs-5 mb-0">
                            ${{ (10 * notes['10']).toFixed(2) }}
                          </p>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center fs-5">$5</td>
                      <td>
                          <Counter @change="(value) => setNote(5, value)" />
                      </td>
                      <td class="text-center">
                          <p class="fs-5 mb-0">
                            ${{ (5 * notes['5']).toFixed(2) }}
                          </p>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center fs-5">$2</td>
                      <td>
                          <Counter @change="(value) => setNote(2, value)" />
                      </td>
                      <td class="text-center">
                          <p class="fs-5 mb-0">
                            ${{ (2 * notes['2']).toFixed(2) }}
                          </p>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center fs-5">$1</td>
                      <td>
                          <Counter @change="(value) => setNote(1, value)" />
                      </td>
                      <td class="text-center">
                          <p class="fs-5 mb-0">
                            ${{ (1 * notes['1']).toFixed(2) }}
                          </p>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center fs-5">$0.5</td>
                      <td>
                          <Counter @change="(value) => setNote(0.5, value)" />
                      </td>
                      <td class="text-center">
                          <p class="fs-5 mb-0">
                            ${{ (0.5 * notes['0.5']).toFixed(2) }}
                          </p>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center fs-5">$0.2</td>
                      <td>
                          <Counter @change="(value) => setNote(0.2, value)" />
                      </td>
                      <td class="text-center">
                          <p class="fs-5 mb-0">
                            ${{ (0.2 * notes['0.2']).toFixed(2) }}
                          </p>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center fs-5">$0.1</td>
                      <td>
                          <Counter @change="(value) => setNote(0.1, value)" />
                      </td>
                      <td class="text-center">
                          <p class="fs-5 mb-0">
                            ${{ (0.1 * notes['0.1']).toFixed(2) }}
                          </p>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                      <tr class="bg-dark">
                          <th class="text-uppercase text-center font-weight-bolder" colspan="2">Total</th>
                          <th id="ototal" class="text-center fs-5 ps-2">
                            ${{ open_sum.toFixed(2) }}
                          </th>
                      </tr>
                  </tfoot>
                </table>
                <div class="container text-end px-0 py-3 px-3">

                  <a href="#!" class="btn btn-success mx-5 mb-2 mt-3 shadow-none" @click="send_daysale()">Send</a>
                </div>
              </div>
            </div>
          </div>
          <div class="container" v-if="debug_getActiveShift">
            <div class="row">
              <div class="col-md-5">
                <div class="btn-group-vertical">
                  <button class="btn btn-dark border-bottom border-secondary shadow-none py-3 my-0">
                    Transfer to reception
                  </button>
                  <button class="btn btn-dark border-bottom border-secondary shadow-none py-3 my-0">
                    Add Expense
                  </button>
                  <button class="btn btn-dark border-bottom border-secondary shadow-none py-3 my-0">
                    Add Cash
                  </button>
                  <button class="btn btn-danger1 bg-gradient-danger shadow-none py-3 my-0" @click="close_till">
                    Close till
                  </button>
                </div>
              </div>
              <div class="col-md-7">
                <ul class="list-group py-2">
                  <li class="list-group-item py-0 disabled">
                      <a href="#!" class="d-flex justify-content-between align-items-center">
                          Shift
                          <span class="badge badge-sm badge-success badge-pill">
                              Ongoing
                          </span>
                      </a>
                  </li>
                  
                  <li class="list-group-item py-0 disabled">
                    <a href="#!" class="d-flex justify-content-between align-items-center">
                      Started at
                      
                      <span class="badge badge-sm badge-info badge-pill">
                          {{ debug_getActiveShift.start_datetime }}
                      </span>
                        
                    </a>
                  </li>
                  <li class="list-group-item py-0 disabled">
                      <a href="#!" class="d-flex justify-content-between align-items-center py-1">
                          <div>
                              Register
                              <span class="badge badge-sm badge-info badge-pill">
                                  {{ debug_getActiveShift.get_daySale.register.name }}
                              </span>
                          </div>
                          <span class="badge badge-xs badge-danger badge-pill text-xs" v-if="debug_getActiveShift.get_daySale.closed">
                              Closed
                          </span>
                          <span class="badge badge-xs badge-success badge-pill text-xs" v-else>
                            Opened
                          </span>
                          
                      </a>
                  </li>
                  <li class="list-group-item py-0 disabled">
                      <a href="#!" class="d-flex justify-content-between align-items-center">
                          Expenses
                          <span class="badge badge-sm badge-primary badge-pill">
                              ${{ debug_getActiveShift.get_daySale.otherExpenses }}
                          </span>
                      </a>
                  </li>
                  <li class="list-group-item py-0 disabled">
                      <a href="#!" class="d-flex justify-content-between align-items-center">
                          Cash Transfered
                          <span class="badge badge-sm badge-primary badge-pill">
                              ${{ debug_getActiveShift.get_daySale.cashTransferToReception }}
                          </span>
                      </a>
                  </li>
                  <li class="list-group-item py-0 disabled">
                      <a href="#!" class="d-flex justify-content-between align-items-center">
                          Cash Addition
                          <span class="badge badge-sm badge-primary badge-pill">
                              ${{ debug_getActiveShift.get_daySale.cashAddition }}
                          </span>
                      </a>
                  </li>

                  <li class="list-group-item py-0 disabled">
                      <a href="#!" class="d-flex justify-content-between align-items-center">
                          Opened till
                          <span class="badge badge-sm badge-success badge-pill">
                              ${{ debug_getActiveShift.get_daySale.openTilt_sum.toFixed(2) }}
                          </span>
                      </a>
                  </li>

                  <li class="list-group-item py-0 disabled">
                      <a href="#!" class="d-flex justify-content-between align-items-center">
                          Target
                          <span class="badge badge-sm badge-success badge-pill">
                            ${{ debug_getActiveShift.get_daySale.register.target1 }}
                          </span>
                      </a>
                  </li>
                  <li class="list-group-item py-0 disabled">
                      <a href="#!" class="d-flex justify-content-between align-items-center">
                          Done sales
                          <span class="badge badge-sm badge-success badge-pill">
                              0
                          </span>
                      </a>
                  </li>
                </ul>
              </div>
            </div>
            
          </div>
          
        </template>
      </Card>

    </div>

    <div class="col-12 col-md-6 col-xl-6" v-if="debug_getActiveShift">
      <div class="card h-100">
        <div class="p-3 pb-0 card-header">
          <h6 class="mb-0">Till activity</h6>
        </div>
        <div class="p-3 card-body" v-if="debug_getActiveShift">

          <ul class="list-group">
            
            <li class="list-group-item" v-for="action in debug_getActiveShift.get_daySale.actions.reverse()" :key="action.id">
              <div class="d-flex justify-content-between">
                <div class="text-white text-md">
                  <div class="d-flex">{{ action.action }}</div>
                  <span class="text-sm">{{ action.date_added }}</span>
                </div>
                <span class="fs-5 text-center align-center my-auto" :class="action.amount < 0 ? 'text-danger' : 'text-success'">
                  ${{ action.amount.toFixed(2) }}
                </span>
              </div>
              <p class="text-sm" v-if="action.note">
                <span class="material-icons">note</span>
                {{ action.note }}
              </p>
              <!-- <pre>{{ action}}</pre> -->
            </li>
            <!-- <li class="list-group-item">
              <div class="d-flex justify-content-between">
                <div class="text-white text-md">
                  <div class="d-flex">Sold Red Bull</div>
                  <span class="text-sm">9:48</span>
                </div>
                <span class="fs-5 text-center align-center my-auto text-success">
                  +$10
                </span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="d-flex justify-content-between">
                <div class="text-white text-md">
                  <div class="d-flex">Opened register (Reception)</div>
                  <span class="text-sm">7:00</span>
                </div>
                <span class="fs-5 text-center align-center my-auto">
                  $300
                </span>
              </div>
            </li> -->
          </ul>
          
          

        </div>
        <div class="card-body" v-else>
          Need to open till first
        </div>
      </div>
    </div>
  </div>

  <div class="row my-4" v-if="this.$store.state.app_settings.showDebug">
    <div class="col-md-6">
      <Card :bodyClass="`p-0`">
        <template v-slot:header>getActiveShift</template>
        <template v-slot:body>
          <pre>
            {{ debug_getActiveShift }}
          </pre>
        </template>
      </Card>
    </div>
    <div class="col-md-6">
      <Card :bodyClass="`p-0`">
        <template v-slot:header>Manager shifts</template>
        <template v-slot:body>
          <pre>
            {{ debug_manager_shifts }}
          </pre>
        </template>
      </Card>
    </div>
  </div>

</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import Card from '@/components/Card.vue'
import Counter from '@/components/Counter.vue'
// import Modal from '@/components/Modal.vue'
// import ErrorAlert from '@/components/alerts/ErrorAlert.vue'
// import AlertDefault from '@/components/alerts/AlertDefault.vue'

export default {
  name: 'RegisterView',
  components: {
    Card,
    Counter
  },
  data() { 
    return {
      page: 0,
      shift_type: null,
      register: null,
      open_sum: 0,
      notes: {
        100: 0,
        50: 0,
        20: 0,
        10: 0,
        5: 0,
        2: 0,
        1: 0,
        0.5: 0,
        0.2: 0,
        0.1: 0,
      },
    }
  },
  methods: {
    select_type(ty) { 
      this.shift_type = ty
      this.page++
    },
    select_register(re) { 
      this.register = re
      this.page++
    },
    setNote(note, value) { 
      console.log('set note to :', value);
      this.notes[note] = value
      // this.open_sum += (value * this.notes[note])
      this.open_sum = 0
      Object.entries(this.notes).forEach(entry => {
        const [key, value] = entry;
        console.log(key, value);
        this.open_sum += key * value
      });

      // this.notes.forEach(([key, value]) => { 
      //   console.log('k', key);
      //   console.log('v', value);
      // })
    },
    async send_daysale() {
      this.$store.commit('setIsLoading', true) 
      var data = {
        register: this.register,
        shift: this.shift_type,
        openTilt_sum: this.open_sum,
      }
      // await axios.post(`http://localhost:8000/save-cashup`, data)
      await axios.post(`/daysale`, data)
        .then(response => {
          console.log('save daysale:', response);
          // this.$store.state.profile.getActiveShift = response.data
          this.$store.dispatch('fetchProfile')
        })
        .catch(error => {
          console.log("error:", error);
        })
      this.$store.commit('setIsLoading', false)
    },
    async close_till() {
      this.$store.commit('setIsLoading', true) 
      var data = {
        closeTilt_sum: this.open_sum,
      }
      // await axios.post(`http://localhost:8000/save-cashup`, data)
      await axios.post(`/daysale`, data)
        .then(response => {
          console.log('save daysale:', response);
          this.page = 0
          this.$store.dispatch('fetchProfile')
        })
        .catch(error => {
          console.log("error:", error);
        })
      this.$store.commit('setIsLoading', false)
    },
  },
  computed: {
    debug_getActiveShift() { return this.$store.state.profile.getActiveShift },
    debug_manager_shifts() { return this.$store.state.profile.manager_shifts },
    
  }
}
</script>

<template>
<div class="col-md-2 col-sm-2 text-center my-2 px-1" @refresh="console.log('bcard refresh')">
        
    <div class="card booking-card p-0 shadow-none" :class="active ? 'active-booking': ''">
        
        <div class="card-header p-0 d-flex justify-content-between align-items-center" :class="color">
            <span class="badge badge-pill text-xxs">
                {{ code }}
            </span>
            <div class="beep text-xs px-2" v-if="show_beeper">
                <i @click="toggle_beep" class="material-icons p-1 text-white cursor-pointer fs-5">{{volume}}</i>
            </div>
        </div>
        <div class="card-body p-0 select-booking bg-dark1">
            <ul class="list-group list-group-vertical rounded-0 m-0 p-0 ">
                <li class="list-group-item d-flex justify-content-between align-items-center p-1 text-xs border-0 bg-transparent">
                    {{ booking.b_type }}
                    <div id="timer">{{ countdown }}</div>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center p-1 text-xs border-0 bg-transparent" v-if="booking.extended">
                    {{ booking.b_extended_type }}
                    <i class="material-icons text-success m-0 p-0">chevron_right</i>
                </li>
                <!-- <li class="list-group-item d-flex justify-content-between align-items-center p-1 text-xs border-0">
                    {{ booking.id }}
                </li> -->
                <li class="list-group-item py-0 px-2 text-start border-0 bg-transparent" v-if="booking.escort">
                    <span class="text-xs">ESCORT</span>
                </li>
                <li class="list-group-item py-0 px-2 text-start border-0" v-if="booking.reference">
                    <span class="text-xs">{{ booking.reference.name }}</span>
                </li>
                <li class="list-group-item py-0 disabled border-0 bg-transparent">
                    <a href="#!" class="d-flex justify-content-between align-items-center">
                        <div class="d-flex">
                            <img v-if="booking.sp.image_url" :src="booking.sp.image_url" class="p-0 my-1 avatar avatar-xs me-1 " alt="Avatar" />
                            <img v-else src="@/assets/img/no-avatar.png" class="p-0 my-1 avatar avatar-xs me-1 " alt="Avatar" />
                            
                            <div class="d-flex flex-column justify-content-center text-sm">
                                {{ booking.sp.name }} 
                            </div>
                        </div>
                    </a>
                </li>
                <li class="list-group-item py-0 disabled border-0" v-if="booking.sp2">
                    <a href="#!" class="d-flex justify-content-between align-items-center" v-if="booking.sp2">
                        <div class="d-flex">
                            <img src="@/assets/img/no-avatar.png" class="p-0 my-1 avatar avatar-xs me-1 " alt="Avatar" />
                            <div class="d-flex flex-column justify-content-center text-sm">
                                {{ booking.sp2.name }} 
                            </div>
                        </div>
                    </a>
                </li>
            </ul>
        </div>

    </div>
    
</div>
</template>

<script>
import beep from '@/assets/audio/beep-06.mp3'

export default {
    name: 'BookingCard',
    props: ['booking', 'active'],
    data() { 
        return {
            color: 'bg-dark',
            code: 'Loading...',
            muted: false,
            show_beeper: false,
            volume: !this.muted ? 'volume_up' : 'volume_off',
            // volume_up: 'volume_off',
            countdown: "00:00",
            audio: new Audio(beep)

        }
    },
    methods: {
        time_left(d1, d2, paused_time) { 
            var time_now = new Date();
            var d1 = new Date(d1);//start date
            var d2 = new Date(d2);//finish date
            //finish date

            // console.log('time', d1);
            if (!this.booking.paused) {
                if (time_now <= d1) {
                    var diff_time = d1.getTime() - time_now.getTime();
                    this.color = 'bg-primary'
                    this.code = "Preparing"
                } else if (time_now >= d2) {
                    var diff_time = time_now.getTime() - d2.getTime();
                    this.color = 'bg-danger'
                    this.code = "Finished"
                    this.show_beeper = true
                } else {
                    var diff_time = d2.getTime() - time_now.getTime();
                    // var min = diff_time / 1000 * 60
                    // console.log('ELSE: diff_time', diff_time);
                    // if (min < 10) {
                    //     this.color = 'bg-warning'
                    //     this.show_beeper = true
                    //  } else { 

                    //     this.color = 'bg-success'
                    //     this.code = "Ongoing"
                    // }
                    this.color = 'bg-success'
                    this.code = "Ongoing"
                }
                //
            } else {
                var paused_time = new Date(paused_time);
                this.color = 'bg-info'
                this.code = "Paused"
                var diff_time = d2.getTime() - paused_time.getTime();
            }
            
            var days = Math.floor(diff_time / (1000 * 60 * 60 * 24));
            var hours = Math.floor(diff_time / (1000 * 60 * 60));
            var mins = Math.floor(diff_time / (1000 * 60));
            var secs = Math.floor(diff_time / 1000);

            var d = days;
            var h = hours - days * 24;
            var m = mins - hours * 60;
            var s = secs - mins * 60;

            if (h < 10) { h = '0' + h }
            if (m < 10) { m = '0' + m; }
            if (s < 10) { s = '0' + s }
            

            if (h > 0) {
                var t = h + ":" + m + ":" + s;
            } else {
                var t = m + ":" + s;
            }
            //if 10 min left
            // if (this.booking.status == 'f' && (h == 0 && m < 10)) {
            //     this.color = 'bg-warning'
            // }
            

            return t
        },
        async play_sound() {
            // var min_left = this.time_left(this.booking.start_datetime, this.booking.finish_datetime).split(':')
            // console.log('time_left', min_left);
            // this.volume = 'volume_off'
            if (!this.muted) { 
                await this.audio.play()
                // this.volume = 'volume_up'
            }
        },
        toggle_beep() { 
            this.muted = !this.muted
            if (!this.muted) {
                this.volume = 'volume_up'
            } else {
                this.volume = 'volume_off'
            }
        }
    },
    watch: {
        countdown: {
            handler(value) {
                setTimeout(() => {
                    // console.log(this.booking.status, this.code);
                    // console.log('booking changed', value, value < '10:00');
                    this.countdown = this.time_left(this.booking.start_datetime, this.booking.finish_datetime, this.booking.paused_datetime);
                    var min = parseInt(this.countdown.split(':')[0])
                    console.log('min', min);
                    // if (this.code == 'ongoing' && value < '10:00') { 
                    if (min < 10 && this.code == 'Ongoing') { 
                        this.color = 'bg-warning'
                        this.show_beeper = true
                        this.play_sound()
                    }
                }, 1000);
                
            },
            immediate: true,
        },
    },
    created: () => { 
        console.log("loading.");
    }
    

}
</script>

<style>
.booking-card{
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 14px;
}
.active-booking{
    border:2px solid rgba(255,255,255,0.1);
}

</style>
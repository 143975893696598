<template>
  <div class="card m-0">
        <div class="card-header py-1">
        <h6 class="m-0">Checkout</h6>
        </div>
        <div class="table-responsive">
            <table class="table table-sm table-compact1 align-items-center mb-0">
                <colgroup>
                    <col span="1" style="width: 10%;">
                    <col span="1" style="width: 15%;">
                    <col span="1" style="width: 65%;">
                    <col span="1" style="width: 20%;">
                </colgroup>
                <thead>
                    <tr>
                    <th class="p-0"></th>
                    <th class="text-xxs text-center">Hours</th>
                    <th class="text-xxs">Product</th>
                    <th class="text-xxs text-end">Total</th>
                    </tr>
                </thead>
                
                <tbody class="table-stripped accordion mb-1">
                    <tr class="accordion-item cursor-pointer" data-bs-toggle="collapse" data-bs-target="#details">
                        <td class="text-center">
                            <span class="material-icons-outlined">expand_more</span>
                        </td>
                        <td class="text-center px-1">
                            {{ booking.multiplier }}
                        </td>
                        <td class="text-start px-2">
                            {{ booking.b_type }}
                        </td>
                        <td class="text-center">
                            <span class="fw-bold px-1">
                                ${{ booking.sale.grand_total }}
                            </span>
                        </td>
                    </tr>
                    <tr class="bg-dark opacity-7 accordion-collapse collapse" :class="expandedView ? 'show' : ''" id="details">
                        <td class="text-center">
                            <span class="material-icons-outlined">female</span>
                        </td>
                        <td class="text-center px-0">
                            -
                        </td>
                        <td class="text-start">
                            <p v-if="booking.sp">
                                {{ booking.sp.name }}
                            </p>
                        </td>
                        <td class="text-center">
                            <span class="fw-bold px-1">
                                ${{ booking.sale.supplier_share_total }}
                            </span>
                        </td>
                    </tr>
                    <tr class="bg-dark opacity-7 accordion-collapse collapse" :class="expandedView ? 'show' : ''" id="details">
                        <td class="text-center">
                            <span class="material-icons-outlined">house</span>
                        </td>
                        <td class="text-center px-0">
                            -
                        </td>
                        <td class="text-start">
                            House
                        </td>
                        <td class="text-center">
                            <span class="fw-bold px-1">
                                ${{ booking.sale.house_share }}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>

            <hr class="horizontal dark m-0" />
            
            <div class="d-flex px-2 py-1 justify-content-between mt-0" v-if="showTax">
                <span>Tax (<span id="tax">{{ booking.sale.tax }}%</span>)</span>
                <span class="fw-bold">
                    $<span id="tax_amount">{{ booking.sale.tax_amount }}</span>
                </span>
            </div>
            <div class="d-flex px-2 py-1 justify-content-between mt-0" v-if="showDiscount">
                <span>Discount</span>
                <span class="fw-bold">
                    $<span id="discount_amount">{{ booking.sale.discount }}</span>
                </span>
            </div>
            <div class="d-flex px-3 py-1 justify-content-between mt-0">
                <span>Total (<span id="items_num">{{ booking.sale.items.length }}</span> items)</span>
                <span class="fw-bold px-2">
                    $<span id="total_amount">{{ booking.sale.grand_total }}</span>
                </span>
            </div>
            <hr />
            <div class="btn-group w-100 px-2 mt-2" role="group" v-if="booking.sale.paid">
            <a href="#!" class="btn bg-gradient-secondary btn-block d-flex justify-content-between shadow-none">
                <span>
                <span class="material-icons text-success">currency_exchange</span>
                Refund
                </span>
                <span id="grand_total">${{ booking.sale.grand_total }}</span>
            </a>
            <a href="#!" class="btn bg-gradient-info shadow-none">
                <span class="material-icons">print</span>
                Reciept
            </a> 
            </div>
            <div v-else class="btn-group w-100 px-2 mt-2" role="group">
            <a href="#!" class="btn text-xxs btn-danger shadow-none">
                Cancel
            </a>
            <a href="#!" class="btn btn-success btn-block d-flex justify-content-between shadow-none" @click="$emit('pay')">
                Pay
                <div>
                    $<span id="grand_total">{{ booking.sale.grand_total }}</span>
                </div>
            </a>
            </div>

            <div v-if="this.$store.state.app_settings.showDebug">
                
                <p class="text-xs px-2">CheckoutCard.vue</p>
                <pre class="bg-dark">{{ booking.sale }}</pre>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: "CheckoutCard",
    props: ['booking',],
    data() { 
        return {
            showTax: false,
            //
            expandedView: true,
            showDiscount: false,
            addDiscount: false,
            addNote: false,
        }
    },


}
</script>

<style>

</style>
export default {
    formatDate(dateString) {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('default',
            {
                dateStyle: 'short',
                timeStyle: 'short',
                timeZone: 'Pacific/Auckland',
            }
        ).format(date);
    },
    //sp
    formatRate(rate) {
        if (rate == 's') { return 'Standard' }
        if (rate == 'p') { return 'Premium' }
    },
    //booking
    format_booking_status(status) {
        if (status == 'rn') return 'Ongoing'
        if (status == 'f') return 'Finished'
        if (status == 'c') return 'Canceled'
    },
    get_color(status) {
        if (status == 'rn') return 'bg-success'
        if (status == 'f') return 'bg-danger'
        if (status == 'c') return 'bg-danger'
    },
    //sale
    //- eftpos/credit/cash
}
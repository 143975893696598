<template>
    <div class="modal fade" :id="id" tabindex="-1" role="dialog" :aria-labelledby="id+'Label'" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content" :class="theme">
                <div class="modal-header">
                    <h5 class="modal-title font-weight-normal" :id="id + 'Label'">{{ title }}</h5>
                    <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&nbsp;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <slot name="body"></slot>
                </div>
                <div class="modal-footer">
                    <slot name="footer"></slot>
                    <div v-if="!$slots.footer">
                        <button type="button" class="btn btn-sm bg-gradient-secondary my-0 shadow-none" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-sm bg-gradient-primary my-0 shadow-none">Save changes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Modal",
    props: {
        id: String,
        title: String,
        theme: String,
    }

}
</script>

<style>

</style>
<template>
  
    <div class="input-group d-flex">
        <div class="input-group-prepend">
            <span class="p-0 border border-secondary badge rounded-circle p-1 material-icons text-xl text-secondary cursor-pointer" @click="count_down">remove</span>
        </div>
        <input type="text" class="form-control text-center text-light text-md" v-model="value" />
        <div class="input-group-append">
            <span class="p-0 border border-secondary badge rounded-circle p-1 material-icons text-xl text-secondary cursor-pointer" @click="count_up">add</span>
        </div>
    </div>

</template>

<script>
export default {
    name: "Counter",
    data() { 
        return {
            value: 0,
            min: 0,
            max: null,
        }
    },
    methods: {
        count_up() {
            this.value++
            this.$emit("change", this.value)
        },
        count_down() {
            if (this.value > this.min) { 
                this.value--
            }
            this.$emit("change", this.value)
        },
        // handleChange(event) { 
        //     this.value = parseInt(event.target.value)
        //     console.log(this.value);
        //     this.$emit("change", this.value)
        // }
    }
}
</script>

<style>

</style>
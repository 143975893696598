<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card my-2">
        <div class="card-header p-3 d-flex justify-content-between">
            <h6 class="mb-0">Manager Shifts</h6>
            <div class="dropdown text-end">
                <a href="#!" class="cursor-pointer text-white opacity-8" id="dropdownUsers1" data-bs-toggle="dropdown" aria-expanded="false">
                    <span class="text-xs text-white opacity-8">
                    <input type="date" id="datepicker" class="text-white cursor-pointer" name="trip-start" value="2018-07-22" min="2018-01-01" max="2018-12-31" />
                    </span>
                </a>
                <ul class="dropdown-menu dropdown-menu-end px-2 py-3" aria-labelledby="dropdownUsers1">
                    <li><a class="dropdown-item border-radius-md" href="#">Last 7 days</a></li>
                    <li><a class="dropdown-item border-radius-md" href="#">Last week</a></li>
                    <li><a class="dropdown-item border-radius-md" href="#">Last 30 days</a></li>
                </ul>
            </div>
        </div>
        <div class="card-body p-0">
            <div class="table-responsive">
                <table class="table table-sm table-striped table-hover">
                    <thead>
                        <tr>
                            <th class="text-center1 py-1">-</th>
                            <th class="text-center1 py-1" colspan="2">Manager</th>
                            <th class="text-center py-1">Type</th>
                            <th class="text-center py-1">Till</th>
                            <th class="text-center py-1">Start Time</th>
                            <th class="text-center py-1">Finish Time</th>
                            <th class="text-center py-1">Open Till</th>
                            <th class="text-center py-1">Closed Till</th>
                        </tr>
                    </thead>
                    <tbody>
                        
                        <tr class="cell-1 row-child" v-for="shift in manager_shifts.reverse()" :key="shift.id">
                            <td class="px-2 py-1 text-center">
                                <router-link :to="`/dashboard/manager_shift/${shift.id}`">
                                    <button class="btn btn-flat p-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapse190" aria-expanded="true" aria-controls="collapse190" onclick="loadReciept(190)">
                                        <i class="material-icons">expand_more</i>
                                        <i class="material-icons">expand_less</i>
                                    </button>
                                </router-link>
                            </td>
                            <td class="px-2 py-1">
                                <div class="d-flex px-2">
                                    <div>
                                        <!-- <img v-if="user.image_url" :src="user.image_url" class="avatar avatar-sm rounded-circle me-2"> -->
                                        <!-- <img v-else src="@/assets/img/no-avatar.png" class="avatar avatar-sm rounded-circle me-2"> -->
                                        <img src="@/assets/img/no-avatar.png" class="avatar avatar-sm rounded-circle me-2">
                                    </div>
                                    <div class="my-auto">
                                        <h6 class="mb-0 text-xs">
                                            {{ shift.user }}
                                        </h6>
                                    </div>
                                </div>
                            </td>
                            
                            <td class="px-2 py-1 text-center">
                                <span class="badge badge-success badge-sm" v-if="!shift.finish_datetime">
                                    Ongoing
                                </span>
                                <span class="badge badge-danger badge-sm" v-else>
                                    Finished
                                </span>
                            </td>
                            
                            <td class="px-2 py-1 text-center">
                                {{ shift.shift_type }}
                            </td>
                            <td class="px-2 py-1 text-center">
                                <span class="badge badge-sm badge-dark" v-if="shift['get_daySale']">
                                    <!-- {{ shift['get_daySale'].register }} -->
                                    <span v-if="shift['get_daySale'].register">{{ shift['get_daySale'].register.name}}</span>
                                </span>
                            </td>
                            <td class="px-2 py-1 text-start">
                                <!-- <span class="text-sm">2023-03-15 15:15</span> -->
                                <span class="text-sm">{{ $filters.formatDate(shift.start_datetime) }}</span>
                            </td>
                            <td class="px-2 py-1 text-start">
                                <span class="text-sm">{{ $filters.formatDate(shift.finish_datetime) }}</span>
                            </td>
                            
                            <td class="px-2 py-1 text-end">
                                <span v-if="shift['get_daySale']">
                                    ${{ shift['get_daySale'].openTilt_sum.toFixed(2) }}
                                </span>
                            </td>
                            <td class="px-2 py-1 text-end">
                                <span v-if="shift['get_daySale']">
                                    ${{ shift['get_daySale'].closeTilt_sum.toFixed(2) }}
                                </span>
                            </td>
                        </tr>
                        
                    </tbody>
                    
                    
                </table>
            </div>
        </div>
        
    </div>
  </div>
</div>
</template>

<script>
// import VirtualList from 'vue-virtual-scroll-list'

export default {
  name: "ManagerShiftsView",
  data() {
    return {
      manager_shifts: this.$store.state.manager_shifts,
      // service_providers: [],
      // service_providers_rates: [],
      // search_sp: null,
    }
  },
  methods: {},
    async mounted() { 
        this.$store.commit('setIsLoading', true)
        if (this.$store.getters.getManagerShifts.length == 0) { 
            await this.$store.dispatch('loadManagerShifts')
        }
        this.manager_shifts = this.$store.getters.getManagerShifts.reverse()
        this.$store.commit('setIsLoading', false)
  }
}
</script>

<style>

</style>
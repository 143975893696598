<template>
  <div class="card" :class="`${color}`">
    <div class="card-header" :class="headerClass">
      <slot name="header"></slot>
    </div>
    <div class="card-body" :class="`${bodyClass}`">
      <slot name="body"></slot>
    </div>
    <div class="card-footer" :class="!footerClass ? `p-0` : footerClass">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    color: String,
    headerClass: String,
    bodyClass: String,
    footerClass: String,
  }
}
</script>

<style>

</style>
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import RegisterView from '../views/cash_register/RegisterView.vue'
import BookingsView from '../views/BookingsView.vue'
import ProductsView from '../views/ProductsView.vue'
import LoginView from '../views/LoginView.vue'
import LoginIllustrationView from '../views/LoginIllustration.vue'
import FaqView from '../views/FaqView.vue'
import ProfileView from '../views/user/ProfileView.vue'
import ProfileSettingsView from '../views/user/ProfileSettingsView.vue'
import ServiceProvidersView from '../views/ServiceProvidersView.vue'
import ServiceProviderDetailView from '../views/ServiceProviderDetailView.vue'
import ManagerShiftsView from '../views/manager_shifts/ManagerShiftsView.vue'
import ManagerShiftDetailView from '../views/manager_shifts/ManagerShiftDetailView.vue'
import StoreSettingsView from '../views/StoreSettingsView.vue'

import store from '@/store'



const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requiredLogin: true,
      admin: true,
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/login2',
    name: 'login2',
    component: LoginIllustrationView
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView,
    meta: {
      requiredLogin: true
    }
  },
  {
    path: '/profile/settings',
    name: 'Profile Settings',
    component: ProfileSettingsView,
    parent: 'profile',
    meta: {
      requiredLogin: true
    }
  },

  //dashboard
  {
    path: '/dashboard/register',
    name: 'register',
    component: RegisterView,
    parent: 'dashboard',
    meta: {
      requiredLogin: true
    }
  },
  {
    path: '/dashboard/bookings',
    name: 'bookings',
    component: BookingsView,
    parent: 'dashboard',
    meta: {
      requiredLogin: true
    }
  },
  {
    path: '/dashboard/products',
    name: 'products',
    component: ProductsView,
    parent: 'dashboard',
    meta: {
      requiredLogin: true
    }
  },
  {
    path: '/dashboard/manager_shifts',
    name: 'Manager Shifts',
    component: ManagerShiftsView,
    parent: 'dashboard',
    meta: {
      requiredLogin: true
    }
  },
  {
    path: '/dashboard/manager_shift/:id',
    name: 'Manager Shift Detail',
    component: ManagerShiftDetailView,
    parent: 'manager_shifts',
    meta: {
      requiredLogin: true
    }
  },
  {
    path: '/dashboard/store_settings',
    name: 'Store Settings',
    component: StoreSettingsView,
    parent: 'dashboard',
    meta: {
      requiredLogin: true
    }
  },
  {
    path: '/dashboard/service_providers',
    name: 'Service Providers',
    component: ServiceProvidersView,
    parent: 'dashboard',
    // children: [
    //   {
    //     path: '/:id',
    //     name: 'service_provider',
    //     component: ServiceProviderDetailView,
    //     parent: 'service_providers',
    //     meta: {
    //       requiredLogin: true
    //     }
    //   },
    // ],
    meta: {
      requiredLogin: true
    }
  },
  {
    path: '/dashboard/service_provider/:id',
    name: 'Pervice Provider',
    component: ServiceProviderDetailView,
    parent: 'service_providers',
    meta: {
      requiredLogin: true
    }
  },

  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: {
      // requiredLogin: true
    }
  },
  {
    path: '/faq',
    name: 'faq',
    component: FaqView,
    meta: {
      requiredLogin: true
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
})

router.beforeEach((to, from, next) => {

  if (to.matched.some(record => record.meta.requiredLogin) && !store.state.isAuthenticated) {
    console.log('need authentication');
    next('/login2')
  } else {

    if (to.matched.some(record => record.name == 'login2' || record.name == 'login') && store.state.isAuthenticated) {
      // console.log('already logged in --> /profile');
      next('/')
    } else {
      next()
    }
  }
})

export default router

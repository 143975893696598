<template>
  <div class="row my-2">
    <div class="col-md-8">
      <Card headerClass="py-1 d-flex justify-content-between" bodyClass="p-2">
        <template v-slot:header>
          <h6 class="m-0">New Booking</h6>
        </template>
        <template v-slot:body>
          <div class="form-group">
            <div class="row">
              <div class="col-md-6">
                <ul class="list-group py-2">
                  <li class="list-group-item py-0" v-for="b_type in booking_types" :key="`${b_type}`" @click="setBookingType(b_type)" :class="booking.b_type == b_type.code ? 'active' : ''">
                    <a href="#!" class="d-flex justify-content-between align-items-center">
                        {{ b_type.name }}
                        <span class="badge badge-sm badge-primary badge-pill">${{ b_type.house_price + b_type.sp_price }}</span>
                        <!-- <pre>{{ b_type }}</pre> -->
                    </a>
                  </li>
                </ul>

                <div class="my-4 px-2 d-flex">
                  <ul class="list-group list-group-horizontal mx-auto">
                    <li class="list-group-item cursor-pointer" :class="inHouse ? 'active' : ''" @click="setInhouse()">
                        In House
                    </li>
                    <li class="list-group-item cursor-pointer" :class="inHouse ? '' : 'active'" @click="setEscort()">
                        Escort
                    </li>
                  </ul>
                </div>

                <ul class="list-group py-2" v-if="inHouse">
                  
                  <li class="list-group-item py-1" v-for="room in rooms" :key="room.id" @click="setRoom(room)" :class="room == booking.reference ? 'active' : ''">
                      <a href="#!" class="d-flex justify-content-between align-items-center">
                          {{room.name}}
                          <span v-if="room.isBusy" class="badge badge-sm badge-primary badge-pill">Busy</span>
                      </a>
                  </li>
                  
                </ul>

                <div id="b_location_selector" v-if="!inHouse">
                  <p class="text-sm mb-0 px-2">
                      <i class="material-icons text-warning">warning</i>
                      <span class="font-weight-bold ms-1">Escort</span> Apply a $20 fee.
                  </p>
                  <div class="input-group input-group-outline my-3">
                    <input type="text" name="hotel_name" class="form-control form-control-sm" placeholder="Hotel Name" />
                  </div>
                  <div class="input-group input-group-outline my-3">
                    <input type="text" name="hotel_room_nr" class="form-control form-control-sm" placeholder="Room number" />
                  </div>
                  
                  <div class="form-check">
                      <input class="form-check-input" type="checkbox" name="call_back" id="call_back">
                      <label class="form-check-label" for="call_back">Did you call the number back?</label>
                  </div>
                  <div>
                      <input type="text" name="hotel_phone" value="" class="form-control form-control-sm my-1" placeholder="Hotel Phone Number">
                      <input type="text" name="hotel_street" value="" class="form-control form-control-sm my-1" placeholder="Street">
                      <input type="text" name="hotel_suburb" value="" class="form-control form-control-sm my-1" placeholder="Suburb">
                      <input type="text" name="hotel_city" value="" class="form-control form-control-sm my-1" placeholder="City">
                  </div>
                </div>

              </div>
              <div class="col-md-6">

                <div class="d-flex" v-if="resultQuery.length > 0">

                    
                  <span class="material-icons bg-dark1 border border-dark align-middle p-2 rounded-top1" style="border-color:rgba(0, 0, 0, .125) !important;border-right: 0 !important; border-bottom:0 !important;border-radius: 6px 0px 0px 0px;">search</span>
                  <input type="text" v-model="search_sp" placeholder="Search" class="form-control px-2 rounded-0 rounded-top1 border border-dark text-white bg-transparent" style="border-color:rgba(0, 0, 0, .125) !important;border-left: 0 !important;border-radius: 0px 6px 0px 0px !important;border-bottom:0 !important">
                  
                </div>
                <ul class="list-group py-2 pt-0" v-if="resultQuery.length > 0">
                    
                  <li class="list-group-item py-0" :class="sp == booking.sp ? 'active disabled' : ''" v-for="sp in resultQuery" :key="sp">
                    <a href="#!" class="d-flex justify-content-between align-items-center" @click="setSp(sp)">
                      <div class="d-flex">
                        <div>
                            <img src="@/assets/img/no-avatar.png" class="border p-0 my-1 avatar avatar-sm me-2" alt="Avatar" :class="sp.busy ? 'border-danger' : ''" />
                        </div>
                        <div class="d-flex flex-column justify-content-center">
                            {{sp.name}}
                            <p class="m-0 mb-1 text-xs">
                              <span class="material-icons text-xs text-warning" v-if="sp.getActiveShift.rate == 'p'">star</span>
                              {{ formatRate(sp.getActiveShift.rate)}}
                            </p>
                        </div>
                      </div>
                      <span class="badge badge-sm badge-primary badge-pill" v-if="sp.busy">Busy</span>
                    </a>
                  </li>

                </ul>

                <div class="alert alert-info alert-dismissible text-white fade show my-3" role="alert" v-else>
                  <span class="alert-icon d-block align-middle p-0">
                    <span class="material-icons text-md pe-2">
                      warning
                    </span>
                    <strong>No girls available!</strong>
                  </span>
                  <span class="alert-text">
                    Clock in somebody first!
                    <br />
                    <router-link to="/dashboard/service_providers" class="btn btn-sm btn-dark my-0 shadow-none">
                      <i class="material-icons-round align-middle fs-6">female</i>
                      <span class="nav-link-text">See girls</span>
                    </router-link>
                  </span>
                  
                  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">&nbsp;</button>
                </div>

                  <div class="card shadow-none">
                    
                    <div class="card-body py-1 px-2">
                          
                          <div id="sp_menu" class="list-group border border-dark">
                            <input type="hidden" name="private" value="False">
                            <!-- <a href="#!" class="rounded disabled bg-gradient-dark active py-1 border-0 d-flex align-middle text-sm">
                                <i class="material-icons px-2 fs-5" aria-hidden="true">settings</i>
                                Boooking Preferences
                            </a> -->
                            <a href="#!" data-bs-toggle="modal" data-bs-target="#remove_sp_share_confirm" class="py-2 d-flex border-bottom border-dark" v-if="booking.sp">
                                <i class="material-icons text-danger px-2">person_remove</i>
                                Remove <u class="px-1">{{ booking.sp.name }}'s</u> share
                            </a>
                            <a href="#!" data-bs-toggle="modal" data-bs-target="#add_tip" class="py-2 d-flex border-bottom border-dark"  v-if="booking.sp">
                                <i class="material-icons px-2 text-success" aria-hidden="true">payments</i>Add tip for <u class="px-1">{{ booking.sp.name }}</u>
                            </a>
                            <a href="#!" data-bs-toggle="modal" data-bs-target="#add_purchase" class="d-flex py-2 border-bottom border-dark disabled" v-if="booking.sp">
                                <i class="material-icons px-2 text-warning" aria-hidden="true">shopping_cart</i>Add purchase to&nbsp;<u>{{booking.sp.name}}</u>
                            </a>
                            <a href="#!" data-bs-toggle="modal" data-bs-target="#add_client" class="d-flex py-2 border-bottom border-dark">
                                <i class="material-icons px-2 text-info" aria-hidden="true">person</i>Set client
                            </a>
                            <a href="#!" data-bs-toggle="modal" data-bs-target="#add_note" class="d-flex py-2 border-bottom border-dark">
                                <i class="material-icons px-2 text-info" aria-hidden="true">summarize</i>Add note
                            </a>
                            <a href="#!" data-bs-toggle="modal" data-bs-target="#add_taxi_fee" class="py-2 d-flex">
                                <i class="material-icons px-2 text-warning" aria-hidden="true">local_taxi</i>Add Uber fee
                            </a>
                          </div>
                      </div>
                      <Modal id="add_note" title="Add note" theme="dark-version">
                        <template v-slot:body>
                          <div class="text-center">
                            add note...
                          </div>
                        </template>
                      </Modal>
                      <Modal id="add_taxi_fee" title="Add Uber Fee" theme="dark-version">
                        <template v-slot:body>
                          <div class="text-center">
                            add uber fee.
                          </div>
                        </template>
                      </Modal>
                      <Modal id="add_client" title="Set client" theme="dark-version">
                        <template v-slot:body>
                          <div class="text-center">
                            client
                          </div>
                        </template>
                      </Modal>
                      <Modal id="add_purchase" title="Set purchase" theme="dark-version">
                        <template v-slot:body>
                          <div class="text-center">
                            client
                          </div>
                        </template>
                      </Modal>
                      <Modal id="add_tip" title="Add tip" theme="dark-version">
                        <template v-slot:body>
                          <div class="text-center">
                            client
                          </div>
                        </template>
                      </Modal>
                      <Modal id="remove_sp_share_confirm" title="Are you sure you want to remove share?" theme="dark-version">
                        <template v-slot:body>
                          <div class="text-center">
                            client
                          </div>
                        </template>
                      </Modal>
                      <Modal id="checkout" title="Checkout" theme="dark-version" v-if="booking.sale">
                        <template v-slot:body>
                          <form id="checkout-form">
                            <div class="form-group mb-3">
                                <label for="payable_amount" class="control-label">Payable Amount</label>
                                <input type="text" name="payable_amount" class="form-control form-control-lg rounded-0 text-end" :value="booking.sale.grand_total" required disabled>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div id="paying_details" class="form-group mb-3">
                                        <label for="tendered_amount" class="control-label">Amount Tendered</label>
                                        <input type="number" step="any" name="tendered_amount" id="tendered_amount" class="form-control form-control-lg rounded-0 text-end" :value="booking.sale.grand_total" required>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="payment_type" class="control-label">Payment type</label>
                                    <ul class="list-group list-group-vertical py-2" id="payment_type_selector">
                                        <li class="list-group-item py-0 cursor-pointer" onclick="select_payment_type('cash')">Cash</li>
                                        <li class="list-group-item py-0 cursor-pointer" onclick="select_payment_type('eft_pos')">Eftpos</li>
                                        <li class="list-group-item py-0 cursor-pointer" onclick="select_payment_type('credit_card')">Credit Card
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <a href="#!" class="btn btn-sm btn-primary" onclick="add_payer();">Add payer</a>
                            <div class="form-group mb-3">
                                <label for="payment_change" class="control-label">Change</label>
                                <input type="text" id="payment_change" name="amount_change" class="form-control form-control-lg rounded-0 text-end" value="0.0" required="" disabled="">
                            </div>
                          </form>
                        </template>
                        <template v-slot:footer>
                          <button class="btn btn-success shadow-none" @click="perform_payment()">Pay</button>
                        </template>
                      </Modal>
                  </div>
              </div>
            </div>
            <div class="row" v-if="$store.state.app_settings.showDebug">
              <div class="container-fluid">
                <hr>
                --
                {{ new Date().toLocaleString() }}
                <hr />
                <pre>
                  ☑️pull types?
                  - search for sp/sp2
                  - selector for sp / sp2
                  - selector for type
                  ☑️ selector for house/escort 
                  + fields
                  ☑️ reference
                  - buttons
                  ☑️ Checkout
                </pre>
                <pre class="bg-dark">{{ booking }}</pre>
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>
    <div class="col-md-4">
      <div class="card m-0">
        <CheckoutCard :booking="booking" @pay="save_booking()" />
        
        <div class="table-responsive" v-if="booking.sale && 1 == 2">
            <table class="table table-sm table-compact1 align-items-center mb-0">
              <colgroup>
                <col span="1" style="width: 10%;">
                <col span="1" style="width: 15%;">
                <col span="1" style="width: 65%;">
                <col span="1" style="width: 20%;">
              </colgroup>
              <thead>
                <tr>
                  <th class="p-0">-</th>
                  <th class="text-xxs text-center">Hours</th>
                  <th class="text-xxs">Product</th>
                  <th class="text-xxs text-end">Total</th>
                </tr>
              </thead>
              
              <tbody class="table-stripped">
                <tr>
                  <td class="text-center">
                    <span class="material-icons-outlined">expand_more</span>
                  </td>
                  <td class="text-center px-0">
                    {{ booking.multiplier }}
                  </td>
                  <td class="text-start">
                    {{ booking.b_type }}
                  </td>
                  <td class="text-end">${{ booking.sale.grand_total }}</td>
                </tr>
                <tr class="bg-dark opacity-7">
                  <td class="text-center">
                    <span class="material-icons-outlined">female</span>
                  </td>
                  <td class="text-center px-0">
                    -
                  </td>
                  <td class="text-start" >
                    <span v-if="booking.sp">
                      {{ booking.sp.name }}
                    </span>
                  </td>
                  <td class="text-end">${{ booking.sale.supplier_share_total }}</td>
                </tr>
                <tr class="bg-dark opacity-7">
                  <td class="text-center">
                    <span class="material-icons-outlined">house</span>
                  </td>
                  <td class="text-center px-0">
                    -
                  </td>
                  <td class="text-start">
                    House
                  </td>
                  <td class="text-end">${{ booking.sale.house_share }}</td>
                </tr>
              </tbody>
            </table>
            <hr class="horizontal dark m-0">
            <div class="d-flex px-2 py-1 justify-content-between mt-0">
                <span>Tax (<span id="tax">{{booking.sale.tax}}%</span>)</span>
                <span class="fw-bold">$<span id="tax_amount">{{ booking.sale.tax_amount }}</span></span>
            </div>
            <div class="d-flex px-2 py-1 justify-content-between mt-0">
                <span>Discount</span>
                <span class="fw-bold">$<span id="discount_amount">{{ booking.sale.discount }}</span></span>
            </div>
            <div class="d-flex px-2 py-1 justify-content-between mt-0">
                <span>Total (<span id="items_num">{{ booking.sale.items.length }}</span> items)</span>
                <span class="fw-bold">$<span id="total_amount">{{ booking.sale.grand_total }}</span></span>
            </div>
            <hr>
            <div class="btn-group w-100 px-2 mt-2" role="group">
                <a href="#!" class="btn text-xxs btn-danger shadow-none">
                    Cancel
                </a>
                <a href="#!" @click="checkout()" class="btn btn-success btn-block d-flex justify-content-between shadow-none">
                    Pay
                    <div>
                        $<span id="grand_total">{{ booking.sale.grand_total }}</span>
                    </div>
                </a>
            </div>
            <div v-if="$store.state.app_settings.showDebug">
              <hr>
              <pre>
                {{ booking.sale }}
              </pre>
            </div>
          </div>
      </div>
    </div>
  </div>

</template>

<script>
import Card from '@/components/Card.vue'
import Modal from '@/components/Modal.vue'
import CheckoutCard from '@/components/CheckoutCard.vue'
import axios from 'axios'
import { mapGetters } from 'vuex'
import '@/assets/js/bootstrap.js'



export default {
  name: 'NewBooking',
  // props: ['booking'],
  components: {
    Card,
    Modal,
    CheckoutCard
  },
  data(){
    return {
      booking: {
        "id": null,
        "sp": null,
        "sp2": null,
        "reference": null,
        "sale": {
          "payment_type": null,
          // "code": "f12c50e0-9865-46b1-a1c7-77eb782fca32",
          "sub_total": 0,
          "grand_total": 0,
          "tendered_amount": 0,
          "amount_change": 0,
          "discount": 0,
          "spillage": false,
          "tax_amount": 0,
          "tax": 15,
          "transactions": [],
          "items": [],
          "house_share": 0,
          "supplier_share_total": 0
        },
        "status": null,//"rn",
        "b_type": null,//"1x60m",
        "b_extended_type": "",
        "client": "",
        // "created_datetime": "2023-03-04T11:33:56.619575Z",
        // "updated_datetime": "2023-03-04T11:34:14.855061Z",
        "start_datetime": null,
        "finish_datetime": null,
        "extended_datetime": null,
        "paused_datetime": null,
        "resumed_datetime": null,
        "canceled_datetime": null,
        "multiplier": 1,
        "room_nr": "",
        "call_back": false,
        "notes": "",
        "finish_early": false,
        "private": false,
        "address": null,
      },
      inHouse: true,
      search_sp: null,
      service_providers_onDuty: [],
      rooms: [],
      booking_types: [],
    }
  },
  methods: {
    formatRate(rate) {
      if (rate == 's') { return 'Standard' }
      if (rate == 'p') { return 'Premium' }
    },
    calculate_price(type) { 
      //get btype price..
      console.log("booking type", type);
      if (this.booking.b_type) { 
        this.booking.sale.sub_total = type.house_price + type.sp_price
        this.booking.sale.grand_total = type.house_price + type.sp_price
        this.booking.sale.house_share = type.sp_price
        this.booking.sale.supplier_share_total = type.sp_price
      }
    },
    setBookingType(type) { 
      this.booking.b_type = type.code
      this.calculate_price(type)
    },
    setSp(sp) { 
      this.booking.sp = sp
    },
    setRoom(room) { 
      this.booking.reference = room
    },
    setInhouse() { 
      this.inHouse = true
    },
    setEscort() { 
      this.inHouse = false
    },
    addMinutes(date, minutes) {
      date.setMinutes(date.getMinutes() + minutes);

      return date;
    },
    addLeadingZeros(n) {
      if (n <= 9) {
        return "0" + n;
      }
      return n
    },
    getBookingType(code) { 
      let found = this.booking_types.find(type => type.code == code)
      console.log('found', found, typeof(found));
      return found
      
    },
    // filteredList() { 
    //   return this.service_providers_onDuty.filter((sp) =>
    //     sp.toLowerCase().includes(search_sp.value.toLowerCase())
    //   );
    // },
    async save_booking() { 
      var new_booking = this.booking
      // new_booking.sale = {}
      // new_booking.delete('sale')
      delete new_booking.sale;
      delete new_booking.getBookingsToday;
      new_booking.sp = new_booking.sp.id
      new_booking.reference = new_booking.reference.id

      let duration = this.getBookingType(this.booking.b_type).duration;
      let now = new Date();
      let start = new Date(now.getTime() + this.$store.state.profile.current_store.wait_room * 60000)
      let start_formatted = start.getUTCFullYear() + "-" + this.addLeadingZeros(start.getUTCMonth() + 1) + "-" + this.addLeadingZeros(start.getUTCDate()) + " " + this.addLeadingZeros(start.getUTCHours()) + ":" + this.addLeadingZeros(start.getUTCMinutes()) + ":" + this.addLeadingZeros(start.getUTCSeconds())
      
      let finish = new Date(start.getTime() + duration * 60000)
      let finish_formatted = finish.getUTCFullYear() + "-" + this.addLeadingZeros(finish.getUTCMonth() + 1) + "-" + this.addLeadingZeros(finish.getUTCDate()) + " " + this.addLeadingZeros(finish.getUTCHours()) + ":" + this.addLeadingZeros(finish.getUTCMinutes()) + ":" + this.addLeadingZeros(finish.getUTCSeconds())
      
      new_booking.status = 'rn'
      new_booking.start_datetime = start_formatted
      new_booking.finish_datetime = finish_formatted


      console.log("this booking", this.booking);
      let modal = this.$el.querySelector('#checkout')
      console.log('checkout modal', modal);


      await axios.post(`/booking`, new_booking)
        .then(async response => {
          console.log('response:', response.data);
          this.booking = response.data
          
        })
        .catch(error => {
          console.log("error:", error);
        })
        this.show_checkout()
    },
    show_checkout() { 
      $('#checkout').modal('show');
    },
    async perform_payment() {

      $('#checkout').modal('hide');

      let new_transaction = {
        // "id": 491,
        // "paid": false,
        // "date_added": "2023-03-14T04:03:04.647092Z",
        // "date_updated": "2023-03-14T04:03:04.647248Z",
        // "sale_id": 1509,
        payment_type: "eft_pos",
        note: null,
        amount_topay: this.booking.sale.grand_total,
        amount_tendered: this.booking.sale.grand_total,
        amount_change: 0,
      }
      this.booking.sale.transactions.push(new_transaction)
      
      await axios.post(`/sales/${this.booking.sale.id}`, this.booking.sale)
        .then(async response => {
          console.log('response:', response.data);
          this.booking = response.data
        })
        .then(async () => {
          // this.$emit('refresh')
          await this.start_booking(this.booking.id)
          
          
        })
        .catch(error => {
          console.log("error:", error);
        })
        this.$emit('refresh')
        
    },
    async start_booking(id) {
      console.log('start booking', id);

      let start = new Date().toUTCString()
      let finish = this.addMinutes(start, 60).toUTCString()

      
      var data = {
        status: 'rn',
        // paused: false,
        start_datetime: start,
        finished_datetime: finish,//new Date(this.booking.paused_datetime).getTime() - new Date().getTime(),
      }
      console.log('sending data:', data);

      await axios.post(`/booking/${id}`, data)
        .then(async response => {
          console.log('response:', response);
        })        
        .catch(error => {
          console.log("error:", error);
        })

      this.$emit('refresh')
      // this.booking = {...this.booking, ...data}
      // console.log('emit refresh');
      // this.$emit('refresh', this.booking)
    },
  },
  async mounted() {
    console.log("mounted");

    if (this.$store.getters.getServiceProvidersOnDuty.length == 0) {
      console.log('- load service providers'); 
      await this.$store.dispatch('loadServiceProviders')
    }
    if (this.$store.getters.getBookingTypes.length == 0) { 
      console.log('- load booking options');
      await this.$store.dispatch('loadBookingsOptions')
    }
    if (this.$store.getters.getReferences.length == 0) {
      console.log('- load references');
      await this.$store.dispatch('loadReferences')
    }

    //save booking types, references,
    this.service_providers_onDuty = this.$store.getters.getServiceProvidersOnDuty
    this.booking_types = this.$store.getters.getBookingTypes
    this.rooms = this.$store.getters.getReferences
    // this.service_providers = this.$store.getters.getServiceProviders
    // console.log(this.service_providers);
    // console.log('luxon',this.$luxon("2020-10-05T14:48:00.000Z"));
    // b_options
  },
  computed: {
    // ...mapGetters([
    //   'getServiceProvidersOnDuty',
    //   'getBookingTypes',
    //   'getReferences',
    // ]),
    // getCheckoutModal() {
    //   return this.$refs['checkout']
    // }
    resultQuery() {
      if (this.search_sp) {
        return this.service_providers_onDuty.filter(item => {
          return this.search_sp
            .toLowerCase()
            .split(" ")
            .every(v => item.name.toLowerCase().includes(v));
        });
      } else {
        return this.service_providers_onDuty  ;
      }
    },
  },
    
}
</script>

<style>
table{
  table-layout: fixed
}
</style>
<template>
  <div class="row">
    <div class="col-md-6">
      <div class="card p-0">
        <div class="card-heading py-1 px-3 border-bottom border-dark">
          <h5>Products</h5>
        </div>
        <div class="card-body p-0">
          <div class="table-responsive">
            <table class="table table-sm table-striped">
              <thead>
                <tr>
                  <th>id</th>
                  <th>Product</th>
                  <th>Price</th>
                  <th>Category</th>
                  <th>Inventory</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="product in products" :key="product">
                  <td>{{ product.id }}</td>
                  <td>{{ product.name }}</td>
                  <td>${{ product.price }}</td>
                  <td style="`color: ${product.color}`">
                    <span v-if="product.category_id">
                      {{ product.category_id.name }}
                    </span>
                  </td>
                  <td>
                    <ul v-if="product.inventory_control.length != 0" class="list-group">
                      <li v-for="item in product.inventory_control" :key="item" class="list-group-item">
                        {{ item.id }}
                      </li>
                    </ul>
                    <p class="text-center" v-else>No inventory</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="card">
        <div class="card-heading py-1 px-3 border-bottom border-dark">
          <h5>Inventory</h5>
        </div>
        <div class="card-body p-0">
          <div class="table-responsive">
            <table class="table table-sm table-striped">
              <thead>
                <tr>
                  <th>id</th>
                  <th>Name</th>
                  <th>Category</th>
                  <th>Stock</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in inventory" :key="item.id">
                  <td class="text-center">{{item.id}}</td>
                  <td>{{item.name}}</td>
                  <td>{{item.category}}</td>
                  <td>
                    <ul class="list-group">
                      <li v-if="item.measure == 'unit'" class="list-group-item">{{ item.stock }} {{ item.measure }}</li>
                      <li v-if="item.measure == 'ml'" class="list-group-item">{{ item.volume }} {{ item.measure }}</li>
                      <li v-if="item.measure == 'g'" class="list-group-item">{{ item.weight }} {{ item.measure }}</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" v-if="$store.state.app_settings.showDebug">
    <div class="col-md-6">
      <pre>
        {{ $store.getters.getProducts }}
      </pre>
    </div>
    <div class="col-md-6">
      <pre>
        {{ $store.getters.getInventory }}
      </pre>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ProductsView',
  data() { 
    return {
      products: [],
      inventory: []
    }
  },
  async mounted() { 
    this.$store.commit('setIsLoading', true)
    await this.$store.dispatch('loadProducts')
    await this.$store.dispatch('loadInventory')
    this.products = this.$store.getters.getProducts
    this.inventory = this.$store.getters.getInventory
    console.log('prod:', this.products);
    this.$store.commit('setIsLoading', false)
  },
  methods: {
    getInventoryItem(id) {
      return this.$store.getters.getInventoryById(id)
    }
  },
  computed: {
    ...mapGetters([
      'getProducts',
      'getInventory',
      'getInventoryById',
    ])
  }

}
</script>

<style>

</style>
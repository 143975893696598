<template>
  
  <div class="row">
    <div class="mt-4 col-12 col-md-6 col-xl-6 mt-md-0 my-3">
      <Card v-if="profile">
        <template v-slot:header>
          <div class="row">
            <div class="col-md-8 d-flex align-items-center">
              <h6 class="mb-0">Profile Information</h6>
            </div>
            <div class="col-md-4 text-end">

              <a href="javascript:;">
                <span class="material-icons">manage_accounts</span>
              </a>
            </div>
          </div>
        </template>
        <template v-slot:body>
          <p class="text-sm">
          {{ profile.info }}
          </p>
          <hr class="my-4 horizontal gray-light">
          <ul class="list-group">
            <li class="pt-0 text-sm border-0 list-group-item ps-0">
              <strong class="text-dark">Full Name:</strong>
              {{ profile.user.first_name }}, {{ profile.user.last_name }}
            </li>
            <li class="text-sm border-0 list-group-item ps-0">
              <strong class="text-dark">Mobile:</strong>
              {{profile.mobile}}
            </li>
            <li class="text-sm border-0 list-group-item ps-0">
              <strong class="text-dark">Email:</strong>
              {{profile.user.email}}
            </li>
            <li class="text-sm border-0 list-group-item ps-0">
              <strong class="text-dark">Gender:</strong>
              {{profile.gender}}
            </li>
            <li class="text-sm border-0 list-group-item ps-0">
              <strong class="text-dark">Location:</strong>
              {{profile.location}}
            </li>
          </ul>
          
        </template>
      </Card>

    </div>

    <div class="col-12 col-md-6 col-xl-6">
      <div class="card h-100">
        <div class="p-3 pb-0 card-header">
          <h6 class="mb-0">Platform Settings</h6>
        </div>
        <div class="p-3 card-body">
          <h6 class="text-xs text-uppercase text-body font-weight-bolder"> Account </h6>
          <ul class="list-group">
            <li class="px-0 border-0 list-group-item">
              <div class="form-check form-switch ps-0">
                <input id="flexSwitchCheckDefault" class="form-check-input ms-0" type="checkbox" name="email" checked>
                <label class="form-check-label mb-0 text-body ms-3 text-truncate w-80" for="flexSwitchCheckDefault">Email me when someone press Panic</label>
              </div>
            </li>
            <!-- <li class="px-0 border-0 list-group-item">
              <div class="form-check form-switch ps-0">
                <input id="flexSwitchCheckDefault1" class="form-check-input ms-0" type="checkbox" name="Email">
                <label class="form-check-label mb-0 text-body ms-3 text-truncate w-80" for="flexSwitchCheckDefault1">Email me when someone answers on my post</label>
              </div>
            </li>
            <li class="px-0 border-0 list-group-item">
              <div class="form-check form-switch ps-0">
                <input id="flexSwitchCheckDefault2" class="form-check-input ms-0" type="checkbox" name="Email">
                <label class="form-check-label mb-0 text-body ms-3 text-truncate w-80" for="flexSwitchCheckDefault2">Email me when someone mentions me</label>
              </div>
            </li> -->
          </ul>
              
          <h6 class="mt-4 text-xs text-uppercase text-body font-weight-bolder">Application</h6>
          <ul class="list-group">
            <li class="px-0 border-0 list-group-item">
              <div class="form-check form-switch ps-0">
                <input id="debug" class="form-check-input ms-0" type="checkbox" name="See debug code" v-model="showDebug">
                <label class="form-check-label mb-0 text-body ms-3 text-truncate w-80" for="debug">
                  See debug code
                </label>
              </div>
            </li>
            <li class="px-0 border-0 list-group-item">
              <div class="form-check form-switch ps-0">
                <input id="darkMode" class="form-check-input ms-0" type="checkbox" name="Dark Mode" v-model="darkMode">
                <label class="form-check-label mb-0 text-body ms-3 text-truncate w-80" for="darkMode">
                  Dark Mode
                </label>
              </div>
            </li>
            <li class="px-0 border-0 list-group-item">
              <div class="dropdown">
                <a href="#!" class="cursor-pointer text-white opacity-8" id="dropdownUsers1" data-bs-toggle="dropdown" aria-expanded="false">
                  <span class="btn btn-primary text-xs text-white opacity-8">
                    {{$store.state.profile.current_store.name}}
                  </span>
                  <pre>{{ $store.state.profile.current_store }}</pre>
                </a>
                <ul class="dropdown-menu dropdown-menu-end px-2 py-3" aria-labelledby="dropdownUsers1">
                  <li><a class="dropdown-item border-radius-md" href="#">Last 7 days</a></li>
                  <li><a class="dropdown-item border-radius-md" href="#">Last week</a></li>
                  <li><a class="dropdown-item border-radius-md" href="#">Last 30 days</a></li>
                </ul>
              </div>
            </li>
          </ul>

        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-5">
      <div class="card-body">
        <p class="text-sm">Members of same store</p>
          <ul class="list-group py-2 pt-0"><li class="list-group-item py-0"><a href="#!" class="d-flex justify-content-between align-items-center"><div class="d-flex"><div><img src="@/assets/img/no-avatar.png" class="border p-0 my-1 avatar avatar-sm me-2" alt="Avatar"></div><div class="d-flex flex-column justify-content-center">Amanda <p class="m-0 mb-1 text-xs"><!--v-if--> Standard</p></div></div><!--v-if--></a></li><li class="list-group-item py-0"><a href="#!" class="d-flex justify-content-between align-items-center"><div class="d-flex"><div><img src="@/assets/img/no-avatar.png" class="border p-0 my-1 avatar avatar-sm me-2" alt="Avatar"></div><div class="d-flex flex-column justify-content-center">Amy  <p class="m-0 mb-1 text-xs"><!--v-if--> Standard</p></div></div><!--v-if--></a></li><li class="list-group-item py-0"><a href="#!" class="d-flex justify-content-between align-items-center"><div class="d-flex"><div><img src="@/assets/img/no-avatar.png" class="border p-0 my-1 avatar avatar-sm me-2" alt="Avatar"></div><div class="d-flex flex-column justify-content-center">Amilea <p class="m-0 mb-1 text-xs"><!--v-if--> Standard</p></div></div><!--v-if--></a></li><li class="list-group-item py-0"><a href="#!" class="d-flex justify-content-between align-items-center"><div class="d-flex"><div><img src="@/assets/img/no-avatar.png" class="border p-0 my-1 avatar avatar-sm me-2" alt="Avatar"></div><div class="d-flex flex-column justify-content-center">Eva <p class="m-0 mb-1 text-xs"><span class="material-icons text-xs text-warning">star</span> Premium</p></div></div><!--v-if--></a></li></ul>
      </div>
    </div>
  </div>

  <div class="row my-4" v-if="$store.state.app_settings.showDebug">
    <div class="col-md-6">
      <Card :bodyClass="`p-0`">
        <template v-slot:header>Debug</template>
        <template v-slot:body>
          <pre>
            {{ profile }}
          </pre>
        </template>
      </Card>
    </div>
    <div class="col-md-6">
      <Card :bodyClass="`p-0`">
        <template v-slot:header>Debug</template>
        <template v-slot:body>
          <pre>
            {{ debug_user }}
          </pre>
        </template>
      </Card>
    </div>
  </div>

</template>

<script>
// @ is an alias to /src
import Card from '@/components/Card.vue'
// import Modal from '@/components/Modal.vue'
// import ErrorAlert from '@/components/alerts/ErrorAlert.vue'
// import AlertDefault from '@/components/alerts/AlertDefault.vue'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'ProfileView',
  components: {
    Card
  },
  data() { 
    return {
      darkMode: null,//this.$store.state.app_settings.darkMode,
      showDebug: null,
      muteBookings: null,
      // profile: null,
    }
  },
  methods: {
    // ...mapMutations('setDarkMode'),
    // toggle_darkMode() {
    //   console.log("toggle");
    //   // this.$store.commit('setDarkMode', !this.$store.state.app_settings.darkMode)
    //   this.darkMode = !this.$store.state.app_settings.darkMode
    //   // console.log('darkMode', this.$store.state.app_settings.darkMode);
    //   // this.$store.commit('setDarkMode', !this.$store.state.app_settings.darkMode)
    //   // this.$store.commit('setDarkMode', this.darkMode)
    // },
    // toggle_showDebug() { 
      
    //   this.$store.state.app_settings.showDebug = !this.$store.state.app_settings.showDebug
    // }
  },
  computed: {
    profile() { return this.$store.state.profile },
    debug_user() { return this.$store.state.user },
    darkMode: { 
      get() { return this.$store.state.app_settings.darkMode },
      set() {
        console.log("set...");
        this.$store.commit('setDarkMode', !this.$store.state.app_settings.darkMode)
        this.$emit('darkModeChange')
      }
    },
    showDebug: { 
      get() { return this.$store.state.app_settings.showDebug },
      set() {
        console.log("set...");
        this.$store.commit('setShowDebug', !this.$store.state.app_settings.showDebug)
      }
    },
    muteBookings: { 
      get() { return this.$store.state.app_settings.muteBookings },
      set() {
        console.log("set...");
        this.$store.commit('setMuteBookings', !this.$store.state.app_settings.muteBookings)
      }
    },
    // showDebug() { return this.$store.state.app_settings.showDebug },
  }
}
</script>

<template>
  <div class="row my-2">
      <div class="col-md-8">
        <Card headerClass="py-1 d-flex justify-content-between" bodyClass="p-2">
          <template v-slot:header>
            <h6 class="m-0">Booking details</h6>
            <!-- <span class="material-icons cursor-pointer">close</span> -->
          </template>
          <template v-slot:body>
            <div class="form-group">
              <div class="row">
                <div class="col-md-6">
                  <h5 class="fs-6">Booking type</h5>
                  <ul class="list-group py-2">
                    <li class="list-group-item py-0 active" >
                      <a href="#!" class="d-flex justify-content-between align-items-center">
                          {{ booking.b_type }}
                          <span class="badge badge-sm badge-dark badge-pill">-</span>
                      </a>
                    </li>
                  </ul>

                  <div class="my-4 px-2 d-flex">
                    <ul class="list-group list-group-horizontal mx-auto">
                      <li class="list-group-item cursor-pointer active" onclick="select_b_location('in_house')">
                          In House
                      </li>
                      <li class="list-group-item cursor-pointer" onclick="select_b_location('escort')">
                          Escort
                      </li>
                    </ul>
                  </div>
                  <div v-if="booking.reference">
                    <h5 class="fs-6">Room</h5>
                    <ul class="list-group py-2">
                      <li class="list-group-item py-1 active">
                          <a href="#!" class="d-flex justify-content-between align-items-center">
                              {{ booking.reference.name }}
                              <span class="badge badge-sm badge-primary badge-pill">Busy</span>
                          </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-6">

                  <h5 class="fs-6">Service provider</h5>
                  <ul class="list-group py-2 pt-0">
                      
                    <li class="list-group-item py-0 active">
                      <a href="#!" class="d-flex justify-content-between align-items-center">
                          <div class="d-flex">
                              <div>
                                  <img src="@/assets/img/no-avatar.png" class="border p-0 my-1 avatar avatar-sm me-2" alt="Avatar" :class="booking.sp.busy ? 'border-danger' : ''" />
                              </div>
                              <div class="d-flex flex-column justify-content-center">
                                  {{ booking.sp.name}}
                                  <p class="m-0 mb-1 text-xs">
                                    <span class="material-icons text-xs text-warning">star</span>
                                    {{ formatRate(booking.sp)}}
                                  </p>
                              </div>
                          </div>
                          <span class="badge badge-sm badge-primary badge-pill" v-if="booking.sp.busy">Busy</span>
                      </a>
                    </li>
                    
                  </ul>

                    <div class="card shadow-none">
                                
                        <div class="card-body py-1 px-2">
                            
                            <div id="sp_menu" class="list-group border border-dark">
                              <a href="#!" @click="keyReturned_booking(booking.id)" class="py-2 d-flex border-bottom border-dark" v-if="!finished(booking.finish_datetime)">
                                  <i class="material-icons text-dark px-2">key</i>
                                  Key Returned
                              </a>
                              <a href="#!" @click="finishEarly_booking(booking.id)" class="py-2 d-flex border-bottom border-dark" v-if="finished(booking.finish_datetime)">
                                  <i class="material-icons text-success px-2">flag</i>
                                  Finish Early
                              </a>
                              <a href="#!" @click="start_booking(booking.id)" class="py-2 d-flex border-bottom border-dark" v-if="booking.status == 'p' && booking.sale.paid">
                                  <i class="material-icons text-success px-2">play_circle</i>
                                  Start Booking
                              </a>
                              <a href="#!" @click="skipPreparing_booking(booking.id)" class="py-2 d-flex border-bottom border-dark" v-if="finished(booking.start_datetime)">
                                  <i class="material-icons text-info px-2">flag</i>
                                  Skip preparing
                              </a>
                              <a href="#!" class="py-2 d-flex border-bottom border-dark">
                                  <i class="material-icons text-info px-2">fast_forward</i>
                                  Extend Booking
                              </a>
                              <a href="#!" @click="pause_booking(booking.id)" class="py-2 d-flex border-bottom border-dark" v-if="!booking.paused && finished(booking.finish_datetime)">
                                  <i class="material-icons text-warning px-2">pause</i>
                                  Pause Booking
                              </a>
                              <a href="#!" @click="resume_booking(booking.id)" class="py-2 border-bottom border-dark" v-if="booking.paused">
                                <i class="material-icons text-success px-2">play_arrow</i>
                                <span>
                                  Resume Booking
                                  <p class="d-flex1 text-xs px-2 py-0 pt-1 m-0">Booking has been Paused for: 22:53 min</p>
                                </span>
                              </a>
                              <!-- <a href="#!" @click="stop_booking(booking.id)" class="py-2 d-flex border-bottom border-dark">
                                  <i class="material-icons text-danger px-2">stop</i>
                                  Stop Booking
                              </a> -->
                              <a href="#!" @click="cancel_booking(booking.id)" class="py-2 d-flex border-dark">
                                  <i class="material-icons text-danger px-2">cancel</i>
                                  Cancel Booking
                              </a>

                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div class="row bg-dark1" v-if="this.$store.state.app_settings.showDebug">
                <div class="container-fluid">
                  <hr>
                  <ul>
                    <li>Finished: {{ booking.finished }}</li>
                    <li>Finish early: {{ booking.finish_early }}</li>
                    <li>status: {{ booking.status }}</li>
                    <li>start: {{ booking.start_datetime }}</li>
                    <li>finish: {{ booking.finish_datetime }}</li>
                    <li>muted: {{ booking.beep }}</li>
                  </ul>
                  <hr />
                  <pre>{{ booking }}</pre>
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>
      <div class="col-md-4">
        <CheckoutCard :booking="booking" />
      </div>
    </div>
</template>

<script>
import Card from '@/components/Card.vue'
import Accordion from '@/components/Accordion.vue'
import CheckoutCard from '@/components/CheckoutCard.vue'
import axios from 'axios'

export default {
  name: 'manage_bookings',
  props: ['booking'],
  // emits: ['refresh'],
  components: {
    Card,
    Accordion,
    CheckoutCard
  },
  data(){
    return {
      service_providers: [],
      service_providers_onDuty: [],
      rooms: [],
      b_options: {},
    }
  },
  methods: {
    formatRate(rate) {
      if (rate == 's') { return 'Standard' }
      if (rate == 'p') { return 'Premium' }
    },
    formatDate(currentDatetime) {
      let formattedDate = currentDatetime.getUTCFullYear() + "-" + this.addLeadingZeros(currentDatetime.getUTCMonth() + 1) + "-" + this.addLeadingZeros(currentDatetime.getUTCDay()) + " " + this.addLeadingZeros(currentDatetime.getUTCHours()) + ":" + this.addLeadingZeros(currentDatetime.getUTCMinutes()) + ":" + this.addLeadingZeros(currentDatetime.getUTCSeconds())
      return formattedDate
    },
    finished(start_time) {
      var diff = new Date() - new Date(start_time)
      return diff < 0
    },
    addMinutes(date, minutes) {
      return new Date(date.getTime() + minutes * 60000);
    },
    addLeadingZeros(n) {
      if (n <= 9) {
        return "0" + n;
      }
      return n
    },
    async stop_booking(id) { 
      console.log('stop booking', id);

      var data = {
        status: 'f',
        finished: true,

      }

      await axios.post(`/booking/${id}`, data)
        .then(async response => {
          console.log('response:', response);
        })
        .catch(error => {
          console.log("error:", error);
        })

      console.log('emit refresh');
      this.$emit('refresh')
    },
    async pause_booking(id) {
      console.log('pause booking', id);

      var data = {
        status: 'pa',
        paused: true,
        paused_datetime: new Date(),
      }

      await axios.post(`/booking/${id}`, data)
        .then(async response => {
          console.log('response:', response);
        })
        .catch(error => {
          console.log("error:", error);
        })
      
      this.booking.status = 'pa'
      this.booking.paused = true
      this.booking.paused_datetime = new Date()
      // this.booking = {...this.booking, ...data}
      console.log('emit refresh');
      this.$emit('refresh', this.booking)
    },
    async resume_booking(id) {
      console.log('resume booking', id);

      var data = {
        status: 'rn',
        paused: false,
        resumed_datetime: new Date(),
        finished_datetime: new Date(this.booking.paused_datetime).getTime() - new Date().getTime(),
      }

      await axios.post(`/booking/${id}`, data)
        .then(async response => {
          console.log('response:', response);
        })
        .catch(error => {
          console.log("error:", error);
        })

      // this.booking = { ...this.booking, ...data }
      // this.booking = Object.assign(data, this.booking);
      this.booking.status = 'rn'
      this.booking.paused = false
      this.booking.resumed_datetime = new Date(),
      this.booking.finished_datetime = new Date(this.booking.paused_datetime).getTime() - new Date().getTime(),
      console.log('emit refresh');
      this.$emit('refresh', this.booking)
    },
    async cancel_booking(id) {
      console.log('cancel booking', id);

      var data = {
        status: 'c',
        finished: true,
      }

      await axios.post(`/booking/${id}`, data)
        .then(async response => {
          console.log('response:', response);
        })
        .catch(error => {
          console.log("error:", error);
        })

      // this.booking = { ...this.booking, ...data }
      // this.booking = Object.assign(data, this.booking);
      this.booking.status = 'c'
      this.booking.finished = true
      console.log('emit refresh');
      this.$emit('refresh', this.booking)
    },
    async finishEarly_booking(id) {
      console.log('finish early booking', id);

      var data = {
        finish_datetime: new Date(),
        finish_early: true,
      }

      await axios.post(`/booking/${id}`, data)
        .then(async response => {
          console.log('response:', response);
        })
        .catch(error => {
          console.log("error:", error);
        })

      // this.booking = { ...this.booking, ...data }
      // this.booking = Object.assign(data, this.booking);
      this.booking.finish_datetime = new Date()
      this.booking.finish_early = true
      console.log('emit refresh');
      this.$emit('refresh', this.booking)
    },
    async keyReturned_booking(id) {
      console.log('key returned booking', id);

      var data = {
        status: 'f',
        finished: true,
      }

      await axios.post(`/booking/${id}`, data)
        .then(async response => {
          console.log('response:', response);
        })
        .catch(error => {
          console.log("error:", error);
        })

      // this.booking = { ...this.booking, ...data }
      // this.booking = Object.assign(data, this.booking);6
      this.booking.status = 'f'
      this.booking.finished = true
      console.log('emit refresh');
      this.$emit('refresh', this.booking)
    },
    async skipPreparing_booking(id) {
      console.log('key returned booking', id);

      //should be start + duration instead

      var data = {
        start_datetime: new Date(),
        finish_datetime: new Date(new Date(this.booking.finish_datetime).getTime() - this.$store.state.profile.current_store.wait_room * 60000),
      }

      await axios.post(`/booking/${id}`, data)
        .then(async response => {
          console.log('response:', response);
        })
        .catch(error => {
          console.log("error:", error);
        })

      // this.booking = { ...this.booking, ...data }
      // this.booking = Object.assign(data, this.booking);
      this.booking.start_datetime = new Date()
      console.log('emit refresh');
      this.$emit('refresh', this.booking)
    },
    
    async start_booking(id) {
      this.$store.commit('setIsLoading', true)
      console.log('start booking in manage', id);
      let start = new Date()
      let start_formatted = start.getUTCFullYear() + "-" + this.addLeadingZeros(start.getUTCMonth() + 1) + "-" + this.addLeadingZeros(start.getUTCDate()) + " " + this.addLeadingZeros(start.getUTCHours()) + ":" + this.addLeadingZeros(start.getUTCMinutes()) + ":" + this.addLeadingZeros(start.getUTCSeconds())

      let finish = new Date(start.getTime() + 69 * 60000)
      let finish_formatted = finish.getUTCFullYear() + "-" + this.addLeadingZeros(finish.getUTCMonth() + 1) + "-" + this.addLeadingZeros(finish.getUTCDate()) + " " + this.addLeadingZeros(finish.getUTCHours()) + ":" + this.addLeadingZeros(finish.getUTCMinutes()) + ":" + this.addLeadingZeros(finish.getUTCSeconds())

      var data = {
        status: 'rn',
        // paused: false,
        start_datetime: start_formatted,
        finish_datetime: finish_formatted,
      }
      console.log('sending data:', data);

      await axios.post(`/booking/${id}`, data)
        .then(async response => {
          console.log('response:', response);
        })
        .catch(error => {
          console.log("error:", error);
        })

      this.$store.commit('setIsLoading', false)
      this.$emit('refresh')
      // this.booking = {...this.booking, ...data}
      // console.log('emit refresh');
      // this.$emit('refresh', this.booking)
    },
  },
  async mounted() {
    console.log("mounted");
    // await this.$store.dispatch('loadServiceProviders')
    // await this.$store.loadBookingsOptions()
    // await this.$store.dispatch('loadBookingsOptions')
    // console.log("sps:", this.$store.getters.getServiceProviders);
    // // this.service_providers = this.$store.getters.getServiceProviders
    // this.service_providers = this.$store.state.service_providers
    // this.service_providers_onDuty = this.$store.getters.getServiceProvidersOnDuty
    // console.log(this.service_providers);
    // console.log('luxon',this.$luxon("2020-10-05T14:48:00.000Z"));
    // b_options
  },
    
}
</script>

<style>
table{
  table-layout: fixed
}
.hidden{
  display: none;
}
</style>
<template>
    <div class="row">
        <div class="col-12" v-if="girl">
            <div class="card card-body p-1">
                <div class="row justify-content-start align-items-start">
                    <div class="col-sm-auto col-4">
                        <img v-if="girl.image_url" :src="girl.image_url" alt="bruce" class="rounded shadow-sm" :class="girl.busy ? 'border border-danger' : ''" width="200"/>
                        <img v-else src="@/assets/img/no-avatar.png" class="rounded" :class="girl.busy ? 'border border-danger' : ''" alt="" />
                    </div>
                    <div class="col-sm-auto col-8 my-auto">
                        <div class="h-100">
                            <h5 class="mb-1 font-weight-bolder">{{ girl.name }}</h5>
                            <p class="mb-0 font-weight-normal text-sm">
                                {{ $route.params.id }} {{girl.app_id}}
                            </p>
                        </div>
                    </div>
                    <div class="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex1" v-if="girl.getActiveShift != null">
                        <button class="btn btn-sm btn-primary shadow-none mx-1" >Clock out</button>
                        <button class="btn btn-sm btn-primary shadow-none mx-1" v-if="girl.getActiveShift != null && girl.getActiveShift.rate == 'p'">Downgrade to Standard</button>
                        <button class="btn btn-sm btn-primary shadow-none mx-1" v-if="girl.getActiveShift == null">Clock in</button>
                    </div>
                </div>
            </div>

            <div class="card bg-dark my-2" v-if="$store.state.profile.user.groups[0].name == 'Admin'">
                <div class="card-body p-0">
                    <div class="d-lg-flex my-3 p-2">
                        <div>
                            <h5 class="mb-0 d-flex">
                                <span class="material-icons text-info">settings</span>
                                Manage {{ girl.name ?? 'Service Provider' }}
                            </h5>
                            <!-- <p class=" mb-0">
                                <i class="material-icons text-info text-sm">calendar_today</i>
                                <span class="font-weight-bold ms-1">07.03.2023</span>
                            </p>  -->
                        </div>
                        <div class="ms-auto my-auto mt-lg-0 mt-4">
                            <div class="ms-auto my-auto">
                                <a href="#!" class="btn btn-sm btn-primary mx-1">
                                    <span class="material-icons">history</span>
                                    All Payouts
                                </a>
                                <a href="#!" class="btn btn-sm btn-primary mx-1">
                                    <span class="material-icons">edit</span>
                                    Change details
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
            <div class="d-lg-flex my-3">
                <div>
                    <h5 class="mb-0">Date today</h5>
                    <p class=" mb-0">
                        <i class="material-icons text-info text-sm">calendar_today</i>
                        <span class="font-weight-bold ms-1">
                            {{ $filters.formatDate(new Date()) }}
                        </span>
                    </p> 
                </div>
                <div class="ms-auto my-auto mt-lg-0 mt-4">
                    <div class="ms-auto my-auto">
                        <!-- <a href="#!" class="btn btn-sm btn-primary" onclick="payout_history(31)">History of Payouts</a> -->
                        <a href="#!" class="btn btn-sm btn-info shadow-none mx-1">
                            <span class="material-icons">print</span>
                            Print
                        </a>
                        <a href="#!" class="btn btn-sm btn-success shadow-none mx-1">
                            <span class="material-icons">attach_money</span>
                            Pay Out
                        </a>
                    </div>
                </div>
            </div>

            <h6 class="my-3">Bookings</h6>
            <div class="card mb-3">
                <div class="card-body p-0">
                    <div class="table table-responsive" v-if="girl.getBookingsToday">
                        <table class="table table-striped table-hover table-sm mb-0">
                            <thead>
                                <tr>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Booking</th>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Room</th>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Date</th>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Rate</th>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Made</th>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Tips</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="girl.getBookingsToday.length == 0">
                                    <td colspan="7" class="text-center">
                                        <p class="text-sm pt-3">
                                            There are no bookings
                                        </p>
                                    </td>
                                </tr>
                                <tr v-for="booking in girl.getBookingsToday" :key="booking.id">
                                    <td>
                                        <p class="text-sm text-secondary mb-0">{{ booking.b_type }}</p>
                                    </td>
                                    <td>
                                        <p class="text-sm text-secondary mb-0" v-if="booking.reference">{{ booking.reference.name }}</p>
                                        <p v-else>-No-room-</p>
                                    </td>
                                    <td>
                                        <p class="text-sm text-secondary mb-0">{{ $filters.formatDate(booking.finish_datetime) }}</p>
                                    </td>
                                    <td>
                                        <p class="mb-0">
                                            <span class="badge badge-dot me-4">
                                                <i :class="$filters.get_color(booking.status)"></i>
                                                <span class="text-light text-xs">{{ $filters.format_booking_status(booking.status) }}</span>
                                            </span>
                                        </p>
                                    </td>
                                    <td>
                                        <p class="mb-0">
                                            <span class="badge badge-sm">
                                                Standard
                                            </span>
                                        </p>
                                    </td>
                                    <td class="text-sm">
                                        <p class="text-sm mb-0">${{ booking.sale.supplier_share_total }}</p>
                                    </td>
                                    <td class="text-sm">
                                        <p class="text-sm mb-0">
                                            ${{ booking.sale.tip_sum }}
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th class="text-uppercase text-sm" colspan="5">Total from bookings</th>
                                    <th class="text-uppercase text-secondary text-sm ps-2">${{ total_bookings }}</th>
                                </tr>
                                <tr>
                                    <th class="text-uppercase text-sm" colspan="5">Total from tips</th>
                                    <th class="text-uppercase text-secondary text-sm ps-2">${{ total_tips }}</th>
                                </tr>
                                <tr>
                                    <th class="text-uppercase text-sm" colspan="5">Tips deducted (-10%)</th>
                                    <th class="text-uppercase text-secondary text-sm ps-2">${{ total_deducted }}</th>
                                </tr>
                                <tr>
                                    <th class="text-uppercase text-sm font-weight-bolder" colspan="5">Total</th>
                                    <th class="text-uppercase text-secondary text-sm font-weight-bolder ps-2">${{ total_bookings + total_tips - total_deducted }}</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-between">
                <h6 class="my-3">Advances</h6>
                <span>
                    <button class="btn btn-primary btn-sm">
                        <span class="material-icons text-success">monetization_on</span>
                        Add Advance
                    </button>
                </span>
            </div>
            <div class="card mb-3">
                <div class="card-body p-0">
                    <div class="table table-responsive" v-if="girl.sp_advances">
                        <table class="table table-sm table-striped table-hover mb-0">
                            <colgroup>
                                <col width="30%">
                                <col width="40%">
                                <col width="10%">
                                <col width="10%">
                                <col width="10%">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Advance</th>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Date</th>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Amount Total</th>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Amount Left</th>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Status</th>
                                </tr>
                            </thead>
                            <tbody class="border-0">
                                <tr v-if="girl.sp_advances.length == 0">
                                    <td colspan="5" class="text-center">
                                        <p class="text-sm pt-3">
                                            There are no advances
                                        </p>
                                    </td>
                                </tr>
                                <tr v-for="advance in girl.sp_advances" :key="advance.id">
                                    <td>
                                        <p class="text-sm text-secondary mb-0">
                                            {{ advance.notes }}
                                        </p>
                                    </td>
                                    <td>
                                        <p class="text-sm text-secondary mb-0">
                                            {{ $filters.formatDate(advance.date_added) }}
                                        </p>
                                    </td>
                                    <td>
                                        <p class="text-sm text-secondary mb-0">
                                            $ {{ advance.amount }}
                                        </p>
                                    </td>
                                    <td>
                                        <p class="text-sm text-secondary mb-0">
                                            $ {{ advance.amount }}
                                        </p>
                                    </td>
                                    <td class="text-sm">
                                        <p class="text-sm mb-0" v-if="!advance.paid">
                                            <span class="p-0 border border-danger badge rounded-circle p-1 material-icons text-sm text-danger">priority_high</span>
                                            Not Paid
                                        </p>
                                        <p class="text-sm mb-0" v-else>
                                            <span class="p-0 border border-success badge rounded-circle p-1 material-icons text-sm text-success">check</span>
                                            Paid
                                        </p>
                                    </td>
                                </tr>
                                
                                
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th class="text-uppercase text-sm font-weight-bolder" colspan="2">Total</th>
                                    <th class="text-uppercase text-secondary text-sm font-weight-bolder ps-2">${{ total_advances }}</th>
                                    <th class="text-uppercase text-secondary text-sm font-weight-bolder ps-2"></th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-between">
                <h6 class="my-3">Bar purchases</h6>
                <span>
                    <button class="btn btn-primary btn-sm">
                        <span class="material-icons">add_shopping_cart</span>
                        Add Purchase
                    </button>
                </span>
            </div>
            <div class="card mb-3">
                <div class="card-body p-0">
                    <div class="table table-responsive" v-if="girl.sp_cart">
                        <table class="table table-sm table-striped table-hover mb-0">
                            <colgroup>
                                <col width="30%">
                                <col width="40%">
                                <col width="5%">
                                <col width="5%">
                                <col width="10%">
                                <col width="10%">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Product</th>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Date</th>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Price</th>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Quantity</th>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Total</th>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="girl.sp_cart.length == 0">
                                    <td colspan="6" class="text-center">
                                        <p class="text-sm pt-3">
                                            There are no purchases
                                        </p>
                                    </td>
                                </tr>
                                <tr v-for="item in girl.sp_cart" :key="item.id">
                                    <td>
                                        <p class="text-sm text-secondary mb-0">{{ getProduct(item.product).name }}</p>
                                    </td>
                                    <td>
                                        <p class="text-sm text-secondary mb-0">
                                            {{ $filters.formatDate(item.date_added) }}
                                        </p>
                                    </td>
                                    <td>
                                        <p class="text-sm text-secondary mb-0">${{ getProduct(item.product).price }}</p>
                                    </td>
                                    
                                    <td>
                                        <p class="text-sm text-secondary mb-0">{{ item.quantity }}</p>
                                    </td>
                                    <td class="text-sm1">
                                        <p class="text-sm mb-0">${{ getProduct(item.product).price * item.quantity }}</p>
                                    </td>
                                    <td class="text-sm1">
                                        <p class="text-sm mb-0" v-if="!item.paid">
                                            <span class="p-0 border border-danger badge rounded-circle p-1 material-icons text-sm text-danger">priority_high</span>
                                            Not Paid
                                        </p>
                                        <p class="text-sm mb-0" v-else>
                                            <span class="p-0 border border-success badge rounded-circle p-1 material-icons text-sm text-success">check</span>
                                            Paid
                                        </p>
                                    </td>
                                </tr>
                                
                                
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th class="text-uppercase text-sm font-weight-bolder" colspan="4">Total</th>
                                    <th class="text-uppercase text-secondary text-sm font-weight-bolder ps-2">${{ total_purchases }}</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>

            <div class="card mb-7">
                <div class="card-body p-0">
                    <div class="table-responsive">
                        <table class="table table-sm table-striped table-hover m-0">
                            <tbody>
                                <tr>
                                    <td class="text-uppercase text-sm">Total Earned</td>
                                    <td class="text-uppercase text-secondary text-sm font-weight-bolder ps-2">${{ total_earned }}</td>
                                </tr>
                                <tr>
                                    <td class="text-uppercase text-sm">Total Spent</td>
                                    <td class="text-uppercase text-secondary text-sm font-weight-bolder ps-2">${{ total_spent }}</td>
                                </tr>
                                <tr>
                                    <th class="text-uppercase text-sm font-weight-bolder">Total</th>
                                    <th class="text-uppercase text-secondary text-sm font-weight-bolder ps-2">${{ total_total }}</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            

            <div class="row">
                <div class="col-md-4">
                    <div class="card card-body" v-if="this.$store.state.app_settings.showDebug">
                        <p class="text-xs">
                            <pre>{{ girl }}</pre>
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card card-body" v-if="this.$store.state.app_settings.showDebug">
                        <p class="text-xs">
                            getBookingsToday
                            <pre>{{ girl.getBookingsToday }}</pre>
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card card-body" v-if="this.$store.state.app_settings.showDebug">
                        <p class="text-xs">
                            gotTips
                            <pre>{{ girl.gotTips }}</pre>
                        </p>
                    </div>
                </div>
            </div>

        </div>
        <div v-else> -- NO INFO -- </div>
    </div>
</template>

<script>
import { onBeforeMount } from 'vue'
import { mapGetters } from 'vuex'
export default {
    name: "ServiceProviderDetailView",
    props: ['id'],
    // props: {
    //     id: Number,
    // },
    data() { 
        return {
            girl: null,
            total_bookings: 0,
            total_tips: 0,
            total_deducted: 0,
            total_advances: 0,
            total_purchases: 0,
            total_earned: 0,
            total_spent: 0,
            total_total: 0,
        }
    },
    // watch: {
    //     girl: {
    //         handler(value) { 
    //             console.log('value', value);
    //         }
    //     }
    // },
    methods: {
        
        
        calculate() { 
            this.total_bookings = this.girl.getBookingsToday.reduce(
                (accumulator, currentValue) => accumulator + currentValue.sale.house_share,
                this.total_bookings
            );
            
            this.total_advances = this.girl.sp_advances.reduce(
                (accumulator, currentValue) => accumulator + currentValue.amount_left,
                this.total_advances
            );
            //purchases
            this.total_advances = this.girl.sp_advances.reduce(
                (accumulator, currentValue) => accumulator + currentValue.amount_left,
                this.total_advances
            );


            this.total_earned = this.total_bookings

            this.total_total = this.total_earned - this.total_deducted
        },
        getProduct(id) { 
            return this.$store.getters.getProductById(id)
        }
    },
    async mounted() {
        this.$store.commit('setIsLoading', true)
        await this.$store.dispatch('loadServiceProviders')
        await this.$store.dispatch('loadProducts')
        console.log("sp_details mounted, id:");
        // this.girl = this.$store.dispatch('getServiceProviderById', this.$route.params.id)
        this.girl = await this.$store.getters.getServiceProviderById(parseInt(this.$route.params.id))
        // this.get_sp()
        console.log('got:', this.girl);
        this.calculate()
        this.$store.commit('setIsLoading', false)
    },
    computed: {
        // ...mapGetters([
        //     'getServiceProviderById',
        // ]),
        // ...mapGetters({ girl: 'getServiceProviderById' })
        // girl: this.$store.getters.getServiceProviderById,
    },
    

}
</script>

<style>
.table>:not(caption)>*>*{
    border-bottom-width: 0px;
}
</style>
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import bootstrap from 'bootstrap'
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'material-icons/iconfont/material-icons.css';
import filters from './mixins/filters'
// import DateTimeFormatterPlugin from 'vue-datetime-formatter'
// import VueVirtualScroller from 'vue-virtual-scroller'


// axios.defaults.baseURL = 'http://localhost:8000/api'
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api'
axios.defaults.baseURL = 'https://pos133.herokuapp.com/api'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

var app = createApp(App)
app.use(store)

app.config.globalProperties.$filters = filters
// app.use(DateTimeFormatterPlugin)
// Vue.use(VueVirtualScroller)

app.use(router, axios)

// app.use(bootstrap)
// app.use(BootstrapVue)
app.mount('#app')

<template>
  
  <div class="row mb-5">
    <div class="col-lg-3">
      <div class="card position-sticky top-1">
        <ul class="nav flex-column bg-white border-radius-lg p-3">
          <li class="nav-item">
            <a class="nav-link text-white d-flex" data-scroll="" href="#profile">
              <i class="material-icons text-lg me-2">person</i>
              <span class="text-sm">Profile</span>
            </a>
          </li>
          <li class="nav-item pt-2">
            <a class="nav-link text-white d-flex" data-scroll="" href="#basic-info">
              <i class="material-icons text-lg me-2">receipt_long</i>
              <span class="text-sm">Basic Info</span>
            </a>
          </li>
          <li class="nav-item pt-2">
            <a class="nav-link text-white d-flex" data-scroll="" href="#application">
              <i class="material-icons text-lg me-2">settings</i>
              <span class="text-sm">Application</span>
            </a>
          </li>
          <li class="nav-item pt-2">
            <a class="nav-link text-white d-flex" data-scroll="" href="#notifications">
              <i class="material-icons text-lg me-2">campaign</i>
              <span class="text-sm">Notifications</span>
            </a>
          </li>
          <li class="nav-item pt-2">
            <a class="nav-link text-white d-flex" data-scroll="" href="#password">
              <i class="material-icons text-lg me-2">lock</i>
              <span class="text-sm">Change Password</span>
            </a>
          </li>
          <li class="nav-item pt-2">
            <a class="nav-link text-white d-flex" data-scroll="" href="#2fa">
              <i class="material-icons text-lg me-2">security</i>
              <span class="text-sm">2FA</span>
            </a>
          </li>
          
          <li class="nav-item pt-2">
            <a class="nav-link text-white d-flex" data-scroll="" href="#sessions">
              <i class="material-icons text-lg me-2">settings_applications</i>
              <span class="text-sm">Sessions</span>
            </a>
          </li>
          <li class="nav-item pt-2">
            <a class="nav-link text-white d-flex" data-scroll="" href="#delete">
              <i class="material-icons text-lg me-2">delete</i>
              <span class="text-sm">Delete Account</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    
    <div class="col-lg-9 mt-lg-0 mt-4">

      <div class="card card-body" id="profile">
        <div class="row justify-content-center align-items-center">
          <div class="col-sm-auto col-4">
            <div class="avatar avatar-xl position-relative">
              <img src="../../assets/img/team-3.jpg" alt="bruce" class="w-100 rounded-circle shadow-sm">
            </div>
          </div>
          <div class="col-sm-auto col-8 my-auto">
          <div class="h-100">
              <h5 class="mb-1 font-weight-bolder">{{ name }}</h5>
              <p class="mb-0 font-weight-normal text-sm">CEO / Co-Founder</p>
            </div>
          </div>
          <div class="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">
            <label class="form-check-label mb-0">
              <small id="profileVisibility">
              Switch to invisible
              </small>
            </label>
            <div class="form-check form-switch ms-2 my-auto">
              <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault23" checked="" onchange="visible()">
            </div>
          </div>
        </div>
      </div>

      <div class="card mt-4" id="basic-info">
        <div class="card-header">
          <h5>Basic Info</h5>
        </div>
        <div class="card-body pt-0">
          <div class="row">
            <div class="col-4">
              <div class="input-group input-group-static">
                <label>First Name</label>
                <input type="text" class="form-control" placeholder="Alec" onfocus="focused(this)" onfocusout="defocused(this)">
              </div>
            </div>
            <div class="col-4">
              <div class="input-group input-group-static">
                <label>Last Name</label>
                <input type="text" class="form-control" placeholder="Thompson" onfocus="focused(this)" onfocusout="defocused(this)">
              </div>
            </div>
            <div class="col-4">
              <div class="input-group input-group-static">
                <!-- <label class="form-label mt-4 ms-0">Gender</label> -->
                <select class="form-select form-select-sm1" aria-label=".form-select-sm example">
                  <option selected>Select gender</option>
                  <option value="1">Male</option>
                  <option value="2">Female</option>
                </select>
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-6">
              <div class="input-group input-group-static">
                <label>Email</label>
                <input type="email" class="form-control" placeholder="example@email.com" onfocus="focused(this)" onfocusout="defocused(this)">

                <div class="input-group input-group-outline my-3">
                  <input type="email" class="form-control" placeholder="example@email.com" onfocus="focused(this)" onfocusout="defocused(this)">
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="input-group input-group-static">
                <label>Confirm Email</label>
                <input type="email" class="form-control" placeholder="example@email.com" onfocus="focused(this)" onfocusout="defocused(this)" />
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-6">
              <div class="input-group input-group-static">
                <label>Your location</label>
                <input type="text" class="form-control" placeholder="Sydney, A" onfocus="focused(this)" onfocusout="defocused(this)">
              </div>
            </div>
            <div class="col-6">
              <div class="input-group input-group-static">
                <label>Phone Number</label>
                <input type="number" class="form-control" placeholder="+40 735 631 620" onfocus="focused(this)" onfocusout="defocused(this)">
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="container">
              <button class="btn bg-gradient-success btn-sm float-end mt-6 mb-0">Update basic info</button>
            </div>
          </div>


        </div>
      </div>
      <div class="card mt-4" id="application">
        <div class="card-header">
          <h5>Application</h5>
        </div>
        <div class="card-body">
          <h6 class="text-xs text-uppercase text-body font-weight-bolder">Account</h6>
          <ul class="list-group">
            <li class="px-0 border-0 list-group-item"><div class="form-check form-switch ps-0"><input id="flexSwitchCheckDefault" class="form-check-input ms-0" type="checkbox" name="email"><label class="form-check-label mb-0 text-body ms-3 text-truncate w-80" for="flexSwitchCheckDefault">Show debug information</label></div></li>
            
            <li class="px-0 border-0 list-group-item"><div class="form-check form-switch ps-0"><input id="flexSwitchCheckDefault1" class="form-check-input ms-0" type="checkbox" name="Email"><label class="form-check-label mb-0 text-body ms-3 text-truncate w-80" for="flexSwitchCheckDefault1">Training mode</label></div></li>

          </ul>
          <h6 class="mt-4 text-xs text-uppercase text-body font-weight-bolder"> Application </h6>
          
        </div>
      </div>
      <div class="card mt-4" id="notifications">
        <div class="card-header">
          <h5>Notifications</h5>
          <p class="text-sm">Choose how you receive notifications. These notification settings apply to the things you’re watching.</p>
        </div>
        <div class="card-body pt-0">
          <div class="table-responsive">
            <table class="table mb-0">
              <thead>
                <tr>
                  <th class="ps-1" colspan="4">
                    <p class="mb-0">Activity</p>
                  </th>
                  <th class="text-center">
                    <p class="mb-0">Email</p>
                  </th>
                  <th class="text-center">
                    <p class="mb-0">Push</p>
                  </th>
                  <th class="text-center">
                    <p class="mb-0">SMS</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="ps-1" colspan="4">
                    <div class="my-auto">
                      <span class="text-dark d-block text-sm">Mentions</span>
                      <span class="text-xs font-weight-normal">Notify when another user mentions you in a comment</span>
                    </div>
                  </td>
                  <td>
                    <div class="form-check form-switch mb-0 d-flex align-items-center justify-content-center">
                      <input class="form-check-input" checked="" type="checkbox" id="flexSwitchCheckDefault11">
                    </div>
                  </td>
                  <td>
                    <div class="form-check form-switch mb-0 d-flex align-items-center justify-content-center">
                      <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault12">
                    </div>
                  </td>
                  <td>
                    <div class="form-check form-switch mb-0 d-flex align-items-center justify-content-center">
                      <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault13">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="ps-1" colspan="4">
                    <div class="my-auto">
                      <span class="text-dark d-block text-sm">Comments</span>
                      <span class="text-xs font-weight-normal">Notify when another user comments your item.</span>
                    </div>
                  </td>
                  <td>
                    <div class="form-check form-switch mb-0 d-flex align-items-center justify-content-center">
                      <input class="form-check-input" checked="" type="checkbox" id="flexSwitchCheckDefault14">
                    </div>
                  </td>
                  <td>
                    <div class="form-check form-switch mb-0 d-flex align-items-center justify-content-center">
                      <input class="form-check-input" checked="" type="checkbox" id="flexSwitchCheckDefault15">
</div>
</td>
<td>
<div class="form-check form-switch mb-0 d-flex align-items-center justify-content-center">
<input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault16">
</div>
</td>
</tr>
<tr>
<td class="ps-1" colspan="4">
<div class="my-auto">
<span class="text-dark d-block text-sm">Follows</span>
<span class="text-xs font-weight-normal">Notify when another user follows you.</span>
</div>
</td>
<td>
<div class="form-check form-switch mb-0 d-flex align-items-center justify-content-center">
<input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault17">
</div>
</td>
<td>
<div class="form-check form-switch mb-0 d-flex align-items-center justify-content-center">
<input class="form-check-input" checked="" type="checkbox" id="flexSwitchCheckDefault18">
</div>
</td>
<td>
<div class="form-check form-switch mb-0 d-flex align-items-center justify-content-center">
<input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault19">
</div>
</td>
</tr>
<tr>
<td class="ps-1" colspan="4">
<div class="my-auto">
<p class="text-sm mb-0">Log in from a new device</p>
</div>
</td>
<td>
<div class="form-check form-switch mb-0 d-flex align-items-center justify-content-center">
<input class="form-check-input" checked="" type="checkbox" id="flexSwitchCheckDefault20">
</div>
</td>
<td>
<div class="form-check form-switch mb-0 d-flex align-items-center justify-content-center">
<input class="form-check-input" checked="" type="checkbox" id="flexSwitchCheckDefault21">
</div>
</td>
<td>
<div class="form-check form-switch mb-0 d-flex align-items-center justify-content-center">
<input class="form-check-input" checked="" type="checkbox" id="flexSwitchCheckDefault22">
</div>
</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>

      <div class="card mt-4" id="password">
        <div class="card-header">
          <h5>Change Password</h5>
        </div>
        <div class="card-body pt-0">
          <div class="input-group input-group-outline">
            <label class="form-label">Current password</label>
            <input type="password" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)">
          </div>
          <div class="input-group input-group-outline my-4">
            <label class="form-label">New password</label>
            <input type="password" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)">
          </div>
          <div class="input-group input-group-outline">
            <label class="form-label">Confirm New password</label>
            <input type="password" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)">
          </div>
          <h5 class="mt-5">Password requirements</h5>
          <p class="text-muted mb-2">
            Please follow this guide for a strong password:
          </p>
          <ul class="text-muted ps-4 mb-0 float-start">
            <li><span class="text-sm">One special characters</span></li>
            <li><span class="text-sm">Min 6 characters</span></li>
            <li><span class="text-sm">One number (2 are recommended)</span></li>
            <li><span class="text-sm">Change it often</span></li>
          </ul>
          <button class="btn bg-gradient-dark btn-sm float-end mt-6 mb-0">Update password</button>
        </div>
      </div>

<div class="card mt-4" id="2fa">
<div class="card-header d-flex">
<h5 class="mb-0">Two-factor authentication</h5>
<span class="badge badge-success ms-auto mb-auto">Enabled</span>
</div>
<div class="card-body">
<div class="d-flex">
<p class="my-auto">Security keys</p>
<p class="text-secondary text-sm ms-auto my-auto me-3">No Security Keys</p>
<button class="btn btn-sm btn-outline-dark mb-0" type="button">Add</button>
</div>
<hr class="horizontal dark">
<div class="d-flex">
<p class="my-auto">SMS number</p>
<p class="text-secondary text-sm ms-auto my-auto me-3">+4012374423</p>
<button class="btn btn-sm btn-outline-dark mb-0" type="button">Edit</button>
</div>
<hr class="horizontal dark">
<div class="d-flex">
<p class="my-auto">Authenticator app</p>
<p class="text-secondary text-sm ms-auto my-auto me-3">Not Configured</p>
<button class="btn btn-sm btn-outline-dark mb-0" type="button">Set up</button>
</div>
</div>
</div>




<div class="card mt-4" id="sessions">
<div class="card-header pb-3">
<h5>Sessions</h5>
<p class="text-sm">This is a list of devices that have logged into your account. Remove those that you do not recognize.</p>
</div>
<div class="card-body pt-0">
<div class="d-flex align-items-center">
  <div class="text-center w-5">
    <span class="material-icons-outlined">desktop_windows</span>
  </div>
<div class="my-auto ms-3">
  <div class="h-100">
    <p class="text-sm mb-1">
    Bucharest 68.133.163.201
    </p>
    <p class="mb-0 text-xs">
    Your current session
    </p>
  </div>
</div>
<span class="badge badge-success badge-sm my-auto ms-auto me-3">Active</span>
<p class="text-secondary text-sm my-auto me-3">EU</p>
<a href="javascript:;" class="text-primary text-sm icon-move-right my-auto">See more
<i class="fas fa-arrow-right text-xs ms-1" aria-hidden="true"></i>
</a>
</div>
<hr class="horizontal dark">
<div class="d-flex align-items-center">
<div class="text-center w-5">
<span class="material-icons-outlined">desktop_windows</span>
</div>
<p class="my-auto ms-3">Chrome on macOS</p>
<p class="text-secondary text-sm ms-auto my-auto me-3">US</p>
<a href="javascript:;" class="text-primary text-sm icon-move-right my-auto">See more
<i class="fas fa-arrow-right text-xs ms-1" aria-hidden="true"></i>
</a>
</div>
<hr class="horizontal dark">
<div class="d-flex align-items-center">
<div class="text-center w-5">
<span class="material-icons-outlined">phone_iphone</span>
</div>
<p class="my-auto ms-3">Safari on iPhone</p>
<p class="text-secondary text-sm ms-auto my-auto me-3">US</p>
<a href="javascript:;" class="text-primary text-sm icon-move-right my-auto">See more
<i class="fas fa-arrow-right text-xs ms-1" aria-hidden="true"></i>
</a>
</div>
</div>
</div>

<div class="card mt-4" id="delete">
<div class="card-body">
<div class="d-flex align-items-center mb-sm-0 mb-4">
<div class="w-50">
<h5>Delete Account</h5>
<p class="text-sm mb-0">Once you delete your account, there is no going back. Please be certain.</p>
</div>
<div class="w-50 text-end">
<button class="btn btn-outline-secondary mb-3 mb-md-0 ms-auto" type="button" name="button">Deactivate</button>
</div>
</div>
</div>
</div>
</div>
</div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import Modal from '@/components/Modal.vue'
// import ErrorAlert from '@/components/alerts/ErrorAlert.vue'
// import AlertDefault from '@/components/alerts/AlertDefault.vue'

export default {
  name: 'ProfileSettingsView',
  components: {
    // Modal,
  },
  computed: {
    name() { return this.$store.state.user.name }
  }
}
</script>

<template>

  <div class="row mb-2" v-if="manager_shift">
    <div class="col-md-4">
      <div class="card">
        <div class="card-body p-0">
          <ul class="list-group list-group-vertical">
            <li class="list-group-item py-0 disabled">
                <a href="#!" class="d-flex justify-content-between align-items-center">
                    <span><i class="fa fa-user me-1" aria-hidden="true"></i> User</span>
                    <span class="badge badge-sm badge-secondary badge-pill">
                      {{ manager_shift.user }}
                    </span>
                </a>
            </li>
            <li class="list-group-item py-0 disabled">
                <a href="#!" class="d-flex justify-content-between align-items-center">
                  <span>Shift</span>
                  <span class="badge badge-sm badge-dark badge-pill">
                    <i class="material-icons text-sm me-1">nightlight</i>
                    {{ manager_shift.shift_type }}
                  </span>
                </a>
            </li>
            
            <li class="list-group-item py-0 disabled">
                <a href="#!" class="d-flex justify-content-between align-items-center">
                  <span><i class="fa fa-clock-o me-1" aria-hidden="true"></i>Start Time</span>
                  <span class="badge badge-sm badge-secondary badge-pill">
                      2023-03-16 17:18
                  </span>
                </a>
            </li>
            <li class="list-group-item py-0 disabled">
                <a href="#!" class="d-flex justify-content-between align-items-center">
                    <span><i class="fa fa-clock-o me-1" aria-hidden="true"></i>Finish Time</span>
                    
                    <span class="badge badge-sm badge-info">Ongoing..</span>
                    
                </a>
            </li>
            
            <li class="list-group-item py-0 disabled">
                <a href="#!" class="d-flex justify-content-between align-items-center">
                    <span><i class="fa fa-walking me-1" aria-hidden="true"></i>Walkins</span>
                    <span class="badge badge-sm badge-secondary badge-pill">
                      {{ manager_shift.walk_in }}
                    </span>
                </a>
            </li>
          </ul>

          <div class="pe-2 my-2">
              <div class="progress m-1 rounded-3" style="height: 35px;width: 100%;">
                  <div class="progress-bar bg-gradient-info rounded-3" role="progressbar" style="width: 30%;height: 35px;" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">30%</div>
              </div>
          </div>
            
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card">
        <div class="card-header p-2">
          <h5>Register</h5>
        </div>
        <div class="card-body p-0">
          <ul class="list-group">
            <li class="list-group-item py-0 disabled">
                <a href="#!" class="d-flex justify-content-between align-items-center">
                    <span>
                        <i class="fa fa-cash-register me-1" aria-hidden="true"></i>Register
                    </span>
                    <span class="badge badge-sm badge-secondary badge-pill">
                      {{ manager_shift.get_daySale.register.name }}
                    </span>
                </a>
            </li>
            <li class="list-group-item py-0 disabled">
                <a href="#!" class="d-flex justify-content-between align-items-center">
                    <span>
                        <i class="fa fa-money-bill me-1" aria-hidden="true"></i>
                        Open sum
                    </span>
                    <span class="badge badge-sm badge-secondary badge-pill">
                      ${{ manager_shift.get_daySale.openTilt_sum.toFixed(2) }}
                    </span>
                </a>
            </li>
            <li class="list-group-item py-0 disabled">
                <a href="#!" class="d-flex justify-content-between align-items-center">
                    <span>
                        <i class="fa fa-money-bill me-1" aria-hidden="true"></i>
                        Close sum
                    </span>
                    
                    <span class="badge badge-sm badge-danger badge-pill">
                      ${{ manager_shift.get_daySale.closeTilt_sum.toFixed(2) }}
                    </span>
                    
                </a>
            </li>
            <li class="list-group-item py-0 disabled">
                <a href="#!" class="d-flex justify-content-between align-items-center">
                    <span><i class="fa fa-circle me-1" aria-hidden="true"></i> Cash Addition</span>
                    <span class="badge badge-sm badge-secondary badge-pill">
                      ${{ manager_shift.get_daySale.cashAddition.toFixed(2) }}
                    </span>
                </a>
            </li>
            <li class="list-group-item py-0 disabled">
                <a href="#!" class="d-flex justify-content-between align-items-center">
                    <span><i class="fa fa-circle me-1" aria-hidden="true"></i>Cash Transfer</span>
                    <span class="badge badge-sm badge-secondary badge-pill">
                      ${{ manager_shift.get_daySale.cashTransferToReception.toFixed(2) }}
                    </span>
                </a>
            </li>
            <li class="list-group-item py-0 disabled">
                <a href="#!" class="d-flex justify-content-between align-items-center">
                    <span><i class="fa fa-circle me-1" aria-hidden="true"></i>Expenses</span>
                    <span class="badge badge-sm badge-secondary badge-pill">
                      ${{ manager_shift.get_daySale.otherExpenses.toFixed(2) }}
                    </span>
                </a>
            </li>

            <li class="list-group-item py-0 disabled">
                <a href="#!" class="d-flex justify-content-between align-items-center">
                    <span><i class="fa fa-flag me-1" aria-hidden="true"></i> Target</span>
                    <span class="badge badge-sm badge-success badge-pill">
                      ${{ manager_shift.get_daySale.target }}
                    </span>
                </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <ul>
        <li class="list-group-item py-0 disabled">
          <a href="#!" class="d-flex justify-content-between align-items-center">
            Done sales
            <span class="badge badge-sm badge-success badge-pill">
                $0
            </span>
          </a>
        </li>
        <li class="list-group-item py-0 disabled">
          <a href="#!" class="d-flex justify-content-between align-items-center">
            Earned by Girls
            <span class="badge badge-sm badge-danger badge-pill">
              $0
            </span>
          </a>
        </li>
        <li class="list-group-item py-0 disabled">
          <a href="#!" class="d-flex justify-content-between align-items-center">
            Earned from bookings
            <span class="badge badge-sm badge-success badge-pill">
              $0
            </span>
          </a>
        </li>
        <li class="list-group-item py-0 disabled">
          <a href="#!" class="d-flex justify-content-between align-items-center">
            Earned from bar
            <span class="badge badge-sm badge-success badge-pill">
              $0
            </span>
          </a>
        </li>
        <li class="list-group-item py-0 disabled bg-gradient-success1">
          <a href="#!" class="d-flex justify-content-between align-items-center">
            <span>
              <i class="fa fa-cc-visa me-1" aria-hidden="true"></i> EFT-POS
            </span>
            <span class="badge badge-sm1 badge-success1 badge-pill">
              $0
            </span>
          </a>
        </li>
        <li class="list-group-item py-0 disabled bg-gradient-warning1">
          <a href="#!" class="d-flex justify-content-between align-items-center">
            <span><i class="fa fa-money-bill me-1" aria-hidden="true"></i>CASH</span>
            <span class="badge badge-sm1 badge-success1 badge-pill">
              $0
            </span>
          </a>
        </li>
        <li class="list-group-item py-0 disabled bg-gradient-danger1">
          <a href="#!" class="d-flex justify-content-between align-items-center">
            <span><i class="fa fa-credit-card me-1" aria-hidden="true"></i>CREDIT-CARD</span>
            <span class="badge badge badge-success1 badge-pill">
              $0
            </span>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="row" v-if="manager_shift">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">Bookings</div>
        <div class="card-body p-0">
          <div class="table-responsive">
            <table class="table table-sm table-striped table-hover">
              <thead>
                <tr>
                  <th>Girl</th>
                  <th>room</th>
                  <th>start/finish</th>
                  <th>type/status</th>
                  <th>payment</th>
                  <th>house</th>
                  <th>girls</th>
                  <th>tips</th>
                  <th>total</th>
                  
                </tr>
              </thead>
              <tbody>
                <tr v-for="booking in manager_shift.bookings" :key="booking.id">
                  <td>
                    <router-link :to="`/dashboard/service_provider/${booking.sp}`">
                      <div class="d-flex px-2">
                        <div>
                          <img v-if="get_sp(booking.sp).image_url" :src="get_sp(booking.sp).image_url" class="avatar avatar-sm rounded-circle me-2" :class="get_sp(booking.sp).busy ? 'border border-danger' : ''">
                          <img v-else src="@/assets/img/no-avatar.png" class="avatar avatar-sm rounded-circle me-2" :class="get_sp(booking.sp).busy ? 'border border-danger' : ''">
                        </div>
                        <div class="my-auto">
                          <h6 class="mb-0 text-xs">{{ get_sp(booking.sp).name }}</h6>
                        </div>
                      </div>
                    </router-link>
                  </td>
                  <td>
                    <span v-if="booking.reference">
                      {{ booking.reference.name }}
                    </span>
                  </td>
                  <td>
                    <span class="d-flex">{{ $filters.formatDate(booking.finish_datetime) }}</span>
                    <span class="d-flex">{{ $filters.formatDate(booking.start_datetime) }}</span>
                  </td>
                  <td class="text-center">
                    <span class="d-flex">
                      {{booking.multiplier}}
                      {{booking.b_type}}
                    </span>
                    <span class="d-flex">
                      <span class="badge badge-sm" :class="$filters.get_color(booking.status)">
                        {{ $filters.format_booking_status(booking.status) }}
                        <span v-if="booking.finish_early">Early</span>
                      </span>
                    </span>
                  </td>
                  
                  <td>
                    <div v-if="booking.sale">
                      <span class="badge badge-sm badge-dark" v-for="t in booking.sale.transactions" :key="t.id">
                        {{ t.payment_type }}
                      </span>
                    </div>
                    <div v-else>
                      -no-payment-
                    </div>
                  </td>
                  
                  <td>
                    <span v-if="booking.sale">
                      ${{booking.sale.house_share}}
                    </span>
                  </td>
                  <td>
                    <span v-if="booking.sale">
                      ${{ booking.sale.supplier_share_total }}
                    </span>
                  </td>
                  <td>
                    <span v-if="booking.sale">
                      ${{ booking.sale.tip_sum }}
                    </span>
                  </td>
                  <td>
                    <span v-if="booking.sale">
                      ${{ booking.sale.grand_total }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12 my-2">
      <div class="card">
        <div class="card-header">
          Tips (outside booking)
        </div>
        <div class="card-body p-0">
          <div class="table-responsive">
            <table>
              <thead></thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>2</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 my-2">
      <div class="card">
        <div class="card-header">
          Bar sales
        </div>
        <div class="card-body p-0">
          <div class="table-responsive">
            <table>
              <thead></thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12 my-2">
      <div class="card">
        <div class="card-header">
          Spillage sales
        </div>
        <div class="card-body p-0">
          <div class="table-responsive">
            <table>
              <thead></thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12 my-2">
      <div class="card">
        <div class="card-header">
          Spillage
        </div>
        <div class="card-body p-0">
          <div class="table-responsive">
            <table>
              <thead></thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12 my-2">
      <div class="card">
        <div class="card-header">
          Payouts
        </div>
        <div class="card-body p-0">
          <div class="table-responsive">
            <table>
              <thead></thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6">till</div>
  </div>

  <div class="row" v-if="this.$store.state.app_settings.showDebug">
    <div class="col-md-12">
      <div class="card my-2">
        <div class="card-header p-3 d-flex justify-content-between">
            <h6 class="mb-0">Manager Shift</h6>
        </div>
        <div class="card-body p-0">
            <pre>{{ manager_shift }}</pre>
        </div>
        
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "ManagerShiftDetailView",
  data() {
    return {
      manager_shift: null,
      // service_providers: [],
      // service_providers_rates: [],
      // search_sp: null,
    }
  },
  methods: {
    get_sp(id) {
      // return this.$store.getters.getProductById(id)
      return this.$store.getters.getServiceProviderById(id)
    }
  },
  async mounted() { 
    this.$store.commit('setIsLoading', true)
    if (this.$store.getters.getServiceProviders.length == 0) {
      await this.$store.dispatch('loadServiceProviders')
    }
    if (this.$store.getters.getManagerShifts.length == 0) {
      await this.$store.dispatch('loadManagerShifts')
    }
    // await this.$store.dispatch('loadManagerShifts')
    this.manager_shift = this.$store.getters.getManagerShiftById(parseInt(this.$route.params.id))
    this.$store.commit('setIsLoading', false)
  }
}
</script>

<style>

</style>